import { Action, ActionReducerMap, createReducer, MetaReducer, on } from '@ngrx/store';
import * as actions from './actions';
import * as Auth from '../auth/state/reducer';
import * as Campaigns from '../campaigns/state/reducer';
import * as Clients from '../clients/state/reducer';
import * as Conversation from '../conversation/state/reducer';
import * as Conversations from '../waiting-room/state/reducer';
import * as Messages from '../messages/state/reducer';
import * as Notifications from '../notifications/state/reducer';
import * as Patients from '../patients/state/reducer';
import * as Payments from '../payments/state/reducer';
import * as Practices from '../practices/state/reducer';
import * as Search from '../search/state/reducer';
import * as Users from '../users/state/reducer';
import * as Templates from '../templates/state/reducer';
import * as Dialogs from '../dialogs/state/reducer';
import * as Media from '../media/state/reducer';
import * as ProductRequest from '../product-requests/state/reducer';
import * as Dashboard from '../dashboard/state/reducer';
import * as Forms from '../forms/state/reducer';
import * as Viewers from '../viewers/state/reducer';
import { Currency } from '../models/Currency';
import {Tag} from '../models/Tag';
import { User } from '../models/User';

export enum STATE_KEY {
  AUTH = 'auth',
  CAMPAIGNS = 'campaigns',
  CLIENTS = 'clients',
  CONVERSATION = 'conversation',
  CONVERSATIONS = 'conversations',
  MESSAGES = 'messages',
  NOTIFICATIONS = 'notifications',
  PATIENTS = 'patients',
  PRACTICES = 'practices',
  PAYMENTS = 'payments',
  SEARCH = 'search',
  SYSTEM = 'system',
  USERS = 'users',
  TEMPLATES = 'templates',
  DIALOGS = 'dialogs',
  MEDIA = 'media',
  PRODUCT_REQUESTS = 'product-requests',
  DASHBOARD = 'dashboard',
  FORMS = 'forms',
  VIEWERS = 'viewers',
}

export interface AppState {
  [STATE_KEY.AUTH]: Auth.AuthState;
  [STATE_KEY.CAMPAIGNS]: Campaigns.CampaignState;
  [STATE_KEY.CLIENTS]: Clients.ClientState;
  [STATE_KEY.CONVERSATION]: Conversation.ConversationState;
  [STATE_KEY.CONVERSATIONS]: Conversations.ConversationsState;
  [STATE_KEY.MESSAGES]: Messages.MessagesState;
  [STATE_KEY.NOTIFICATIONS]: Notifications.NotificationsState;
  [STATE_KEY.PATIENTS]: Patients.PatientState;
  [STATE_KEY.PAYMENTS]: Payments.PaymentsState;
  [STATE_KEY.PRACTICES]: Practices.PracticesState;
  [STATE_KEY.SEARCH]: Search.SearchState;
  [STATE_KEY.SYSTEM]: SystemState;
  [STATE_KEY.USERS]: Users.UsersState;
  [STATE_KEY.TEMPLATES]: Templates.TemplateState;
  [STATE_KEY.DIALOGS]: Dialogs.DialogState;
  [STATE_KEY.MEDIA]: Media.MediaState;
  [STATE_KEY.PRODUCT_REQUESTS]: ProductRequest.ProductRequestState;
  [STATE_KEY.DASHBOARD]: Dashboard.DashboardState;
  [STATE_KEY.FORMS]: Forms.FormsState;
  [STATE_KEY.VIEWERS]: Viewers.ViewersState;
}

export const reducers: ActionReducerMap<AppState> = {
  [STATE_KEY.AUTH]: Auth.reducer,
  [STATE_KEY.CAMPAIGNS]: Campaigns.reducer,
  [STATE_KEY.CLIENTS]: Clients.reducer,
  [STATE_KEY.CONVERSATION]: Conversation.reducer,
  [STATE_KEY.CONVERSATIONS]: Conversations.reducer,
  [STATE_KEY.MESSAGES]: Messages.reducer,
  [STATE_KEY.NOTIFICATIONS]: Notifications.reducer,
  [STATE_KEY.PATIENTS]: Patients.reducer,
  [STATE_KEY.PAYMENTS]: Payments.reducer,
  [STATE_KEY.PRACTICES]: Practices.reducer,
  [STATE_KEY.SEARCH]: Search.reducer,
  [STATE_KEY.SYSTEM]: reducer,
  [STATE_KEY.USERS]: Users.reducer,
  [STATE_KEY.TEMPLATES]: Templates.reducer,
  [STATE_KEY.DIALOGS]: Dialogs.reducer,
  [STATE_KEY.MEDIA]: Media.reducer,
  [STATE_KEY.PRODUCT_REQUESTS]: ProductRequest.reducer,
  [STATE_KEY.DASHBOARD]: Dashboard.reducer,
  [STATE_KEY.FORMS]: Forms.reducer,
  [STATE_KEY.VIEWERS]: Viewers.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = [];

export interface SystemState {
  newConversationOpen: boolean;
  websocketConnections: string[];
  tourMode: boolean;
  healthy: boolean;
  pmsHealthy: boolean;
  websocketHealthy: boolean;
  mainNavOpen: boolean;
  oneSignalUserId: string | null;
  oneSignalNotificationsEnabled: boolean;
  currencies: Currency[];
  tags: Tag[];
  fullScreen: boolean;
  conversationPreviewEnabled: boolean;
  conversationPreviewOpen: boolean;
  previewConversationId: string | null;
  viewers: User[];
}

export const initialState: SystemState = {
  newConversationOpen: false,
  websocketConnections: [],
  tourMode: false,
  healthy: true,
  pmsHealthy: true,
  websocketHealthy: true,
  mainNavOpen: false,
  oneSignalUserId: null,
  oneSignalNotificationsEnabled: false,
  currencies: [],
  tags: [],
  fullScreen: false,
  conversationPreviewEnabled: false,
  conversationPreviewOpen: false,
  previewConversationId: null,
  viewers: [],
};

const systemReducer = createReducer(
  initialState,
  on(actions.AddWebsocketConnection, (state, payload) => ({
    ...state,
    websocketConnections: [
      ...state.websocketConnections,
      payload.roomName
    ]
  })),
  on(actions.RemoveWebsocketConnection, (state, payload) => ({
    ...state,
    websocketConnections: state.websocketConnections.filter(connection => connection !== payload.roomName)
  })),
  on(actions.SetTourMode, (state, payload) => ({
    ...state,
    tourMode: payload.tourMode
  })),
  on(actions.OpenMainNav, (state) => ({
    ...state,
    mainNavOpen: true
  })),
  on(actions.CloseMainNav, (state) => ({
    ...state,
    mainNavOpen: false
  })),
  on(actions.ToggleMainNav, (state) => ({
    ...state,
    mainNavOpen: !state.mainNavOpen
  })),
  on(actions.SetHealthy, (state, payload) => ({
    ...state,
    healthy: payload.healthy
  })),
  on(actions.SetPmsHealthy, (state, payload) => ({
    ...state,
    pmsHealthy: payload.healthy
  })),
  on(actions.SetWebsocketHealthy, (state, payload) => ({
    ...state,
    websocketHealthy: payload.healthy
  })),
  on(actions.SetOneSignalUserId, (state, payload) => ({
    ...state,
    oneSignalUserId: payload.userId
  })),
  on(actions.SetOneSignalNotificationsEnabled, (state, payload) => ({
    ...state,
    oneSignalNotificationsEnabled: payload.enabled
  })),
  on(actions.GetCurrenciesSuccess, (state, payload) => ({
    ...state,
    currencies: payload.currencies
  })),
  on(actions.GetTagsSuccess, (state, payload) => ({
    ...state,
    tags: payload.tags
  })),
  on(actions.EnterFullScreen, (state) => ({
    ...state,
    fullScreen: true,
  })),
  on(actions.ExitFullScreen, (state) => ({
    ...state,
    fullScreen: false,
  })),
  on(actions.SetConversationPreviewOpen, (state, payload) => {
    if (state.conversationPreviewEnabled) {
      return {
        ...state,
        conversationPreviewOpen: payload.open,
      };
    }

    return {
      ...state
    };
  }),
  on(actions.SetPreviewConversationId, (state, payload) => {
    if (state.conversationPreviewEnabled) {
      return {
        ...state,
        previewConversationId: payload.id,
      };
    }

    return {
      ...state
    };
  }),
  on(actions.ToggleConversationPreviewEnabled, (state) => {
    if (state.conversationPreviewEnabled) {
      return {
        ...state,
        conversationPreviewOpen: false,
        previewConversationId: null,
        conversationPreviewEnabled: !state.conversationPreviewEnabled,
      };
    }

    return {
      ...state,
      conversationPreviewEnabled: !state.conversationPreviewEnabled,
      conversationPreviewOpen: true,
    };
  }),
);

export function reducer(state: SystemState | undefined, action: Action): SystemState {
  return systemReducer(state, action);
}
