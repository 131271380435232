import {MessageStatus} from '../enums/message-status';
import {CurrencyCode} from '../enums/currency-code';
import {ReadStatus} from "../enums/read-status";
import {MessageType} from '../enums/message-type';
import {Channel} from "../enums/channel";
import {Role} from "../enums/role";

export const tourConversation = {
  id: '0',
  watchers: [],
  owner: {
    id: '0',
    firstName: 'Receptionist',
    lastName: 'A',
    fullName: 'Receptionist A',
    email: 'test@test.com',
    phone: '07850123123',
    channel: Channel.INTERNAL,
  },
  labels: [],
  status: {
    id: '0',
    name: 'With clinic',
    color: '#2F80ED',
    order: 0,
  },
  channel: Channel.SMS,
  client: {
    id: '0',
    pmsId: '0',
    title: 'Mr',
    firstName: 'John',
    lastName: 'Smith',
    fullName: 'John Smith',
    fullNameWithPmsId: 'John Smith (1234)',
    email: 'johnsmith@gmail.com',
    phone: '07850123123',
    address1: '123 Fake Street',
    address2: 'Faketon',
    address3: '',
    postcode: 'FA12 3KE',
    state: 'Fakeshire',
    payments: [],
    accountStatus: '-125',
    accountType: '',
    lastTransactionAmount: '25',
    paymentHistory: [],
    financialNotes: 'Notes here',
    clientNotes: 'further notes here',
    nextAppointmentAt: new Date(),
    lastTransactionAt: new Date(),
    mobileContacts: [{
      name: 'Cell',
      typeCode: 'phone',
      value: '07850123123',
      facebookLinked: false,
      instagramLinked: false
    }],
    siteId: null,
    depositHeld: false
  },
  patient: {
    id: '0',
    name: 'Cooper',
    species: 'Dog',
    age: '2',
    weight: '14kg',
    sex: 'Male',
    breed: 'Cocker Spaniel',
    careplan: [
      'lorem ipsum dolor',
      'lorem ipsum dolor'
    ],
    patientNotes: 'Notes go here',
    reminders: [],
    lastSeenAt: new Date(),
    pmsId: '12345'
  },
  timeSinceLastClientResponse: 0,
  timeSinceLastClinicResponse: 0,
  timeSinceCreated: 0,
  users: [
    {
      id: '0',
      firstName: 'John',
      lastName: 'Smith',
      fullName: 'John Smith',
      email: 'johnsmith@gmail.com',
      phone: '07850123123',
      channel: Channel.SMS,
    }
  ],
  createdAt: new Date(),
  newMessage: 0,
  multiplePotentialClients: 0,
  couldntAttemptMatch: 0,
  failoverToSms: false,
  practice: {
    id: '0',
    coreId: '0',
    name: 'Demo Practice',
    pms: 'RxWorks',
    pmsId: '000000',
    whatsapp_sender: '000000000',
    signature: '',
    features: [],
    logo: null,
    banner: null,
    sites: [],
    currency: CurrencyCode.GBP,
    country: 'GBR',
    paymentLinkUrl: 'https://paymy.vet',
    last_cash_up: null,
    facebookPageId: null,
    userRole: Role.NONE,
    userPermissions: {},
    whatsapp_channel: Channel.WHATSAPP,
    whatsappMaxDailyConversations: 250,
    paymentLinkExpiry: 3,
    messagingEnabled: true,
    accentColor: 'FFFFFF',
  },
  queueMode: false,
  readStatus: ReadStatus.UNSET,
  tags: [],
};

export const tourComments = [
  {
    id: '0',
    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad, totam.',
    author: {
      id: '1',
      firstName: 'Receptionist',
      lastName: 'A',
      fullName: 'Receptionist A',
      email: 'r@a.com',
      phone: '',
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    id: '0',
    content: 'Aut debitis est excepturi facere quia quisquam recusandae sequi sit!',
    author: {
      id: '1',
      firstName: 'Vet',
      lastName: 'B',
      fullName: 'Vet B',
      email: 'r@a.com',
      phone: '',
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  }
];

export const tourMessages = [
  {
    id: '0',
    author: {
      id: '0',
      firstName: 'Receptionist',
      lastName: 'A',
      fullName: 'Receptionist A',
      email: 'Receptionist@A.com',
      phone: '',
    },
    attachments: [],
    content: 'Aperiam, consectetur cum dolorum facere ipsum laborum laudantium nihil possimus provident quasi quis velit, veniam! Cum cupiditate officiis quam quibusdam.',
    outbound: true,
    status: MessageStatus.read,
    createdAt: new Date(),
    type: MessageType.STANDARD,
    error: false,
  },
  {
    id: '0',
    author: {
      id: '0',
      firstName: 'John',
      lastName: 'Smith',
      fullName: 'John Smith',
      email: 'john@smith.com',
      phone: '',
    },
    attachments: [],
    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci architecto autem eaque fugit ipsam iusto molestiae nostrum provident quae quod.',
    outbound: false,
    status: MessageStatus.accepted,
    createdAt: new Date(),
    type: MessageType.STANDARD,
    error: false,
  },
  {
    id: '0',
    author: {
      id: '0',
      firstName: 'Receptionist',
      lastName: 'A',
      fullName: 'Receptionist A',
      email: 'Receptionist@A.com',
      phone: '',
    },
    attachments: [],
    content: 'A consequatur cum cumque eaque eos facilis fugiat, illum minus, nisi odio odit optio quos repellendus sint suscipit temporibus vero.',
    outbound: true,
    status: MessageStatus.read,
    createdAt: new Date(),
    type: MessageType.STANDARD,
    error: false,
  },
  {
    id: '0',
    author: {
      id: '0',
      firstName: 'John',
      lastName: 'Smith',
      fullName: 'John Smith',
      email: 'john@smith.com',
      phone: '',
    },
    attachments: [],
    content: 'Asperiores eaque ex magnam nostrum officiis porro quod vero. Dolore eaque fuga harum illo ipsam nemo nostrum optio repellendus, veritatis.',
    outbound: false,
    status: MessageStatus.accepted,
    createdAt: new Date(),
    type: MessageType.STANDARD,
    error: false,
  },
];

export const tourConversations = [
  {
    id: '0',
    watchers: [],
    labels: [],
    users: [
      {
        channel: Channel.WHATSAPP,
        id: '0',
        firstName: 'Joe',
        lastName: 'Smith',
        fullName: 'Joe Smith',
        email: '',
        phone: '07837041127',
      }
    ],
    status: {
      id: '0',
      name: 'With clinic',
      color: '#2F80ED',
      order: 1
    },
    latestMessage: {
      id: '0',
      content: 'What time does the practice open?',
      outbound: false,
      status: MessageStatus.accepted,
      createdAt: new Date(),
      type: MessageType.STANDARD,
      error: false,
    },
    timeSinceLastClientResponse: null,
    timeSinceLastClinicResponse: null,
    timeSinceCreated: 0,
    newMessage: 1,
    multiplePotentialClients: 0,
    couldntAttemptMatch: 0,
    failoverToSms: false,
    practice: {
      id: '0',
      coreId: '0',
      name: 'Demo Practice',
      pms: 'RxWorks',
      pmsId: '000000',
      whatsapp_sender: '000000000',
      signature: '',
      features: [],
      logo: null,
      banner: null,
      sites: [],
      currency: CurrencyCode.GBP,
      country: 'GBR',
      paymentLinkUrl: 'https://paymy.vet',
      last_cash_up: null,
      facebookPageId: null,
      userRole: Role.NONE,
      userPermissions: {},
      whatsapp_channel: Channel.WHATSAPP,
      whatsappMaxDailyConversations: 250,
      paymentLinkExpiry: 3,
      messagingEnabled: true,
      accentColor: 'FFFFFF',
    },
    queueMode: false,
    readStatus: ReadStatus.UNSET,
    tags: [],
  },
  {
    id: '0',
    watchers: [],
    labels: [],
    users: [
      {
        channel: Channel.WHATSAPP,
        id: '0',
        firstName: 'Joe',
        lastName: 'Smith',
        fullName: 'Joe Smith',
        email: '',
        phone: '07837041127',
      }
    ],
    owner: {
      id: '0',
      firstName: 'Receptionist',
      lastName: 'A',
      fullName: 'Receptionist A',
      email: 'test@test.com',
      phone: '07850123123',
      channel: Channel.INTERNAL,
    },
    status: {
      id: '0',
      name: 'With clinic',
      color: '#2F80ED',
      order: 1
    },
    latestMessage: {
      id: '0',
      content: 'How is my cooper doing after his surgery?',
      outbound: false,
      status: MessageStatus.accepted,
      createdAt: new Date(),
      type: MessageType.STANDARD,
      error: false,
    },
    timeSinceLastClientResponse: null,
    timeSinceLastClinicResponse: null,
    timeSinceCreated: 0,
    newMessage: 1,
    multiplePotentialClients: 0,
    couldntAttemptMatch: 0,
    failoverToSms: false,
    practice: {
      id: '0',
      coreId: '0',
      name: 'Demo Practice',
      pms: 'RxWorks',
      pmsId: '000000',
      whatsapp_sender: '000000000',
      signature: '',
      features: [],
      logo: null,
      banner: null,
      sites: [],
      currency: CurrencyCode.GBP,
      country: 'GBR',
      paymentLinkUrl: 'https://paymy.vet',
      last_cash_up: null,
      facebookPageId: null,
      userRole: Role.NONE,
      userPermissions: {},
      whatsapp_channel: Channel.WHATSAPP,
      whatsappMaxDailyConversations: 250,
      paymentLinkExpiry: 3,
      messagingEnabled: true,
      accentColor: 'FFFFFF',
    },
    queueMode: false,
    readStatus: ReadStatus.UNSET,
    tags: [],
  },
  {
    id: '0',
    watchers: [],
    labels: [],
    users: [
      {
        channel: Channel.WHATSAPP,
        id: '0',
        firstName: 'Joe',
        lastName: 'Smith',
        fullName: 'Joe Smith',
        email: '',
        phone: '07837041127',
      }
    ],
    owner: {
      id: '0',
      firstName: 'Receptionist',
      lastName: 'A',
      fullName: 'Receptionist A',
      email: 'test@test.com',
      phone: '07850123123',
      channel: Channel.INTERNAL,
    },
    status: {
      id: '0',
      name: 'With client',
      color: '#17a58c',
      order: 1
    },
    latestMessage: {
      id: '0',
      content: 'Is there anything else we can help you with?',
      outbound: false,
      status: MessageStatus.accepted,
      createdAt: new Date(),
      type: MessageType.STANDARD,
      error: false,
    },
    timeSinceLastClientResponse: null,
    timeSinceLastClinicResponse: null,
    timeSinceCreated: 0,
    newMessage: 0,
    multiplePotentialClients: 0,
    couldntAttemptMatch: 0,
    failoverToSms: false,
    practice: {
      id: '0',
      coreId: '0',
      name: 'Demo Practice',
      pms: 'RxWorks',
      pmsId: '000000',
      whatsapp_sender: '000000000',
      signature: '',
      features: [],
      logo: null,
      banner: null,
      sites: [],
      currency: CurrencyCode.GBP,
      country: 'GBR',
      paymentLinkUrl: 'https://paymy.vet',
      last_cash_up: null,
      facebookPageId: null,
      userRole: Role.NONE,
      userPermissions: {},
      whatsapp_channel: Channel.WHATSAPP,
      whatsappMaxDailyConversations: 250,
      paymentLinkExpiry: 3,
      messagingEnabled: true,
      accentColor: 'FFFFFF',
    },
    queueMode: false,
    readStatus: ReadStatus.UNSET,
    tags: [],
  }
];
