import {Component,Input} from '@angular/core';
import { Media } from '../../../models/Media';
import { ConversationService } from '../../services/conversation.service';
  
  @Component({
    selector: 'conversation-preview',
    templateUrl: './conversation-preview.component.html',
    styleUrls: ['./conversation-preview.component.scss'],
  })
  export class ConversationMessagePreviewComponent {
    constructor(private conversationService: ConversationService) {}
  
    @Input() selectedMedia: Media | undefined = undefined;
    @Input() previewType: string | null = null;
    @Input() previewOpen = false;
    @Input() device = 'desktop';
    initialMessage = this.conversationService.initialMessage;
  }