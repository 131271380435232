<ng-container *ngIf="(error$ | async)">
    <div class="error">
        <div class="message">{{ (error$ | async)!.errorMessage }}</div>
        <ng-container *ngIf="(error$ | async)!.existingConversation">
            <div class="btn-row">
                <a
                    pButton
                    (click)="goToClientConversation()"
                    label="Go to conversation"
                    class="p-button-first"
                ></a>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="loading && !(error$ | async)">
    <p-progressSpinner
        [style]="{width: '40px', height: '40px', margin: '40px auto', display: 'block'}"
        styleClass="custom-spinner"
        strokeWidth="4"
    ></p-progressSpinner>
</ng-container>
<ng-container *ngIf="!loading && !(error$ | async)">
    <ng-container *ngIf="step === 0">
        <div class="subtitle">Search for client in clinic system</div>
        <client-selector
            [noModal]="true"
            (clientSelected)="selectClient($event)"
        ></client-selector>
        <ng-container *ngIf="messagePhoneDirectly">
            <div class="subtitle">Or enter any phone number</div>
            <div class="phone-input">
                <input
                    pInputText
                    (paste)="validatePhoneInput()"
                    (keydown)="validateKeyPress($event)"
                    (keyup)="$event.key == 'Enter' ? usePhoneNumber() : validatePhoneInput()"
                    maxlength="20"
                    type="text"
                    [(ngModel)]="phoneNumber"
                    placeholder="Phone number"
                />
                <button
                    pButton
                    label="Next"
                    [disabled]="!phoneNumberIsValid"
                    (click)="usePhoneNumber()"
                ></button>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="step === 1">
        <div class="subtitle">Select a contact</div>
        <ng-container *ngIf="selectedClient">
            <div class="contact-selector-wrap">
                <div class="client-details">
                    <div class="name">
                        {{ selectedClient.title }} {{ selectedClient.firstName
                        }} {{ selectedClient.lastName }} ({{
                        selectedClient.pmsId }})
                    </div>
                    <div class="address">
                        {{ selectedClient.address1 ? selectedClient.address1 +
                        ',' : '' }} {{ selectedClient.address2 ?
                        selectedClient.address2 + ',' : '' }} {{
                        selectedClient.address3 ? selectedClient.address3 + ','
                        : '' }} {{ selectedClient.postcode ?
                        selectedClient.postcode + ',' : '' }} {{
                        selectedClient.state }}
                    </div>
                </div>
                <ng-container
                    *ngFor="let contact of this.selectedClient.mobileContacts"
                >
                    <div class="contact-selector">
                        <div class="label">
                            {{ contact.name }}: {{ contact.value }}
                        </div>
                        <button
                            pButton
                            label="Select"
                            class="p-button-sm p-button-ghost"
                            (click)="selectContact(contact)"
                        ></button>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <div class="next-step">
            <ng-container *ngIf="minStep < 1">
                <div class="back" (click)="handleBack(1)">
                    <img src="assets/images/icons/back.svg" alt="" />
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 2">
        <div class="step-2">
            <div class="subtitle">Select preferred channel</div>

            <p-dropdown
                [options]="channels"
                [ngModel]="selectedChannel"
                (ngModelChange)="onChannelChange($event)"
                optionLabel="name"
                optionDisabled="inactive"
                class="channel-dropdown"
            >
                <ng-template pTemplate="selectedItem">
                    <div
                        class="channel-item {{ selectedChannel.className }} channel-item-selected"
                        [class.facebook-linked]="facebookLinked"
                        [class.instagram-linked]="instagramLinked"
                        *ngIf="selectedChannel"
                    >
                        <img
                            [src]="'assets/images/icons/' + selectedChannel.icon"
                            class="channel-icon"
                        />
                        <div>{{ selectedChannel.name }}</div>
                    </div>
                </ng-template>
                <ng-template let-channel pTemplate="item">
                    <div
                        class="channel-item {{ channel.className }}"
                        [class.facebook-linked]="facebookLinked"
                        [class.instagram-linked]="instagramLinked"
                    >
                        <img
                            [src]="'assets/images/icons/' + channel.icon"
                            class="channel-icon"
                        />
                        <div>{{ channel.name }}</div>
                    </div>
                </ng-template>
            </p-dropdown>

            <ng-container
                *ngIf="!facebookLinked && selectedChannel.code === channel.FACEBOOK"
            >
                <div class="no-facebook-link">
                    You cannot initialise a conversation using Facebook
                    Messenger because this user has not yet sent a message into
                    the platform
                </div>
            </ng-container>

            <ng-container
                *ngIf="!instagramLinked && selectedChannel.code === channel.INSTAGRAM"
            >
                <div class="no-instagram-link">
                    You cannot initialise a conversation using Instagram because
                    this user has not yet sent a message into the platform
                </div>
            </ng-container>
        </div>
        <div class="next-step">
            <div>
                <ng-container *ngIf="minStep < 2">
                    <div class="back" (click)="handleBack(2)">
                        <img src="assets/images/icons/back.svg" alt="" />
                    </div>
                </ng-container>
            </div>
            <button
                pButton
                (click)="goToStep(4)"
                label="Next Step"
                class="p-button-first"
            ></button>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 3">
        <div class="subtitle">Conversation currently open</div>
        <p class="descr">A conversation is already open for this client.</p>

        <div class="next-step">
            <div>
                <ng-container *ngIf="minStep < 3">
                    <div class="back" (click)="handleBack(3)">
                        <img src="assets/images/icons/back.svg" alt="" />
                    </div>
                </ng-container>
            </div>
            <button
                pButton
                (click)="goToClientConversation()"
                label="Go to conversation"
                class="p-button-first"
            ></button>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 4">
        <div class="edit-campaign-form-wrap">
            <div
                [ngClass]="device === 'desktop' ? 'edit-campaign-form' : device === 'mobile' && previewOpen == false ? 'mobile-edit-campaign-form' : 'mobile-edit-campaign-form-none'"
                [ngStyle]="{
          'width': (device === 'desktop' && selectedTemplate === null) ? '100%' : '',
          'padding-right': (device === 'desktop' && selectedTemplate === null) ? '0px' : '',
          'border-right': (device === 'desktop' && selectedTemplate === null) ? 'none' : ''
        }"
            >
                <div class="input-row-initial">
                    <div class="subtitle">Initial Message</div>
                    <ng-container
                        *ngIf="device === 'mobile' && previewOpen == false"
                    >
                        <div class="preview-link" (click)="togglePreview()">
                            Preview
                        </div>
                    </ng-container>
                </div>
                <div class="input-row">
                    <p class="descr">
                        The first message that will be sent
                        <ng-container *ngIf="initialMessageRequired"
                            >(required)</ng-container
                        >
                        <ng-container *ngIf="!initialMessageRequired"
                            >(optional)</ng-container
                        >
                    </p>
                </div>
                <div class="input-row">
                    <textarea
                        type="text"
                        class="initial-message-input"
                        [readOnly]="usingTemplate"
                        pInputTextarea
                        [formControl]="initialMessage"
                        #initialMessageInput
                    ></textarea>
                </div>
                <div class="only-weekdays">
                    <div class="under-initial-message">
                        <div>
                            <ng-container
                                *ngIf="templates.length > 0 && !usingTemplate && !selectedTemplate"
                            >
                                <div
                                    class="use-template-link"
                                    (click)="selectTemplate()"
                                >
                                    Use template
                                </div>
                            </ng-container>
                            <ng-container *ngIf="usingTemplate">
                                <div
                                    class="use-template-link"
                                    (click)="editTemplate()"
                                >
                                    Edit template
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="selectedTemplate && !usingTemplate"
                            >
                                <div
                                    class="use-template-link"
                                    (click)="cancelEdits()"
                                >
                                    Cancel edits
                                </div>
                            </ng-container>
                        </div>
                        <div
                            class="character-count"
                            [class.invalid]="initialMessageTooLong"
                        >
                            {{ remainingInitialMessageLength }}
                        </div>
                    </div>
                </div>
                <div class="disclaimer">
                    <ng-container
                        *ngIf="initialMessageRequired && initialMessage.invalid && (initialMessage.dirty || initialMessage.touched) && showInitialMessageError"
                    >
                        <div class="error">
                            You must enter the initial message for this
                            conversation
                        </div>
                    </ng-container>
                    <ng-container *ngIf="initialMessageTooLong">
                        <div class="error">Your message is too long</div>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="selectedTemplate">
                <div
                    [ngClass]="device === 'desktop' ? 'edit-campaign-preview' : device === 'mobile' && previewOpen == true ? 'mobile-edit-campaign-preview' : 'mobile-edit-campaign-preview-none'"
                >
                    <conversation-preview></conversation-preview>
                </div>
            </ng-container>
        </div>
        <div class="btn-row">
            <div class="buttons-left">
                <div class="left">
                    <ng-container *ngIf="minStep < 4">
                        <div class="back" (click)="handleBack(4)">
                            <img src="assets/images/icons/back.svg" alt="" />
                        </div>
                    </ng-container>
                </div>
                <div>
                    <ng-container
                        *ngIf="!usingTemplate && selectedTemplate && (selectedChannel.code === channel.WHATSAPP360 || selectedChannel.code === channel.WHATSAPP360CLOUD)"
                    >
                        <a [href]="helpLink" target="_blank">
                            <span
                                >Edited templates will require consent
                                <i class="pi pi-question-circle"></i
                            ></span>
                        </a>
                    </ng-container>
                    <ng-container
                        *ngIf="device === 'mobile' && previewOpen == true"
                    >
                        <div class="preview-link" (click)="togglePreview()">
                            Close Preview
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="buttons-right">
                <button
                    pButton
                    (click)="createConversation()"
                    label="Start Conversation"
                    class="p-button-first"
                ></button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 5">
        <div class="subtitle">Phone number is invalid</div>
        <p class="descr">Please go back and select a valid number</p>

        <div class="next-step">
            <div>
                <div class="back" (click)="handleBack(2)">
                    <img src="assets/images/icons/back.svg" alt="" />
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
