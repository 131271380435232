import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OneSignal } from 'onesignal-ngx';
import {Observable, Subscription} from 'rxjs';
import { filter, map, takeWhile } from 'rxjs/operators';
import { AuthState } from 'src/app/auth/state/reducer';
import { getUser } from 'src/app/auth/state/selectors';
import { Notification } from 'src/app/models/Notification';
import { GetNotifications } from 'src/app/notifications/state/actions';
import {
  getNotifications,
  getOlderNotifications,
  getTodaysNotifications,
  getTotalNotificationCount,
  getYesterdaysNotifications
} from 'src/app/notifications/state/selectors';
import { EnvironmentService } from 'src/app/services/environment.service';
import { AppState } from 'src/app/state/reducers';
import { getOneSignalNotificationsEnabled } from 'src/app/state/selectors';

@Component({
  selector: 'notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit, OnDestroy {
  @Output() linkClicked = new EventEmitter();
  alive = true;
  notificationsSub?: Subscription;
  todaysNotificationsSub?: Subscription;
  yesterdaysNotificationsSub?: Subscription;
  olderNotificationsSub?: Subscription;
  notifications: Notification[] = [];
  todaysNotifications: Notification[] = [];
  yesterdaysNotifications: Notification[] = [];
  olderNotifications: Notification[] = [];
  totalNotificationCountSub?: Subscription;
  totalNotificationCount = 0;
  showAll = false;
  page = 0;
  perPage = 20;

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.subscribeToAuthUser();
    this.subscribeToNotifications();
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.totalNotificationCountSub?.unsubscribe();
  }

  subscribeToNotifications(): void {
    this.subscribeToAllNotifications();
    this.subscribeToTodaysNotifications();
    this.subscribeToYeterdaysNotifications();
    this.subscribeToOlderNotifications();
    this.subscribeToTotalNotificationCount();
  }

  subscribeToAllNotifications(): void {
    this.notificationsSub = this.store.pipe(select(getNotifications)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((notifs) => {
      this.notifications = notifs;
    });
  }

  subscribeToTodaysNotifications(): void {
    this.todaysNotificationsSub = this.store.pipe(select(getTodaysNotifications)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((notifs) => {
      this.todaysNotifications = notifs;
    });
  }

  subscribeToYeterdaysNotifications(): void {
    this.yesterdaysNotificationsSub = this.store.pipe(select(getYesterdaysNotifications)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((notifs) => {
      this.yesterdaysNotifications = notifs;
    });
  }

  subscribeToOlderNotifications(): void {
    this.olderNotificationsSub = this.store.pipe(select(getOlderNotifications)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((notifs) => {
      this.olderNotifications = notifs;
    });
  }

  subscribeToTotalNotificationCount(): void {
    this.totalNotificationCountSub = this.store.pipe(select(getTotalNotificationCount)).pipe(
      takeWhile(() => this.alive)
    ).subscribe((count) => {
      this.totalNotificationCount = count;
    });
  }

  subscribeToAuthUser(): void {
    this.store.pipe(select(getUser)).pipe(
      filter((user) => user !== null),
      takeWhile(() => this.alive)
    ).subscribe(() => {
      this.store.dispatch(GetNotifications({page: this.page, perPage: this.perPage, unreadOnly: !this.showAll}));
      this.getNotifications();
    });
  }

  handleShowAllChange(): void {
    this.showAll = !this.showAll;
    this.page = 0;
    this.getNotifications();
  }

  handleLinkClicked(): void {
    this.linkClicked.emit();
  }

  loadMore(): void {
    this.page = this.page + 1;
    this.getNotifications();
  }

  getNotifications(): void {
    this.store.dispatch(GetNotifications({page: this.page, perPage: this.perPage, unreadOnly: !this.showAll}));
  }
}
