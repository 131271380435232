import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as AppActions from './actions';
import { AppService } from '../app.service';
import { Currency } from '../models/Currency';
import { CurrencyAdapter } from '../adapters/currency.adapter';
import { getCurrentPractice } from '../practices/state/selectors';
import { Practice } from '../models/Practice';
import { Store } from '@ngrx/store';
import { AppState } from './reducers';
import { of } from 'rxjs';
import { Conversation } from '../models/Conversation';
import { ConversationService } from '../conversation/conversation.service';
import {Tag} from "../models/Tag";
import {TagAdapter} from "../adapters/tag.adapter";
import {TagDto} from "../interfaces/dto/tag.dto";

@Injectable()
export class SystemEffects {
  constructor(
    private actions$: Actions,
    private appService: AppService,
    private currencyAdapter: CurrencyAdapter,
    private tagAdapter: TagAdapter,
    private store: Store<AppState>,
    private conversationService: ConversationService
  ) {
  }

  getHealthyStatus$ = createEffect(() => this.actions$.pipe(
    ofType(AppActions.DoHealthCheck),
    mergeMap(() => this.appService.doHealthCheck()
      .pipe(
        map((result: boolean) => {
          return AppActions.SetHealthy({healthy: result});
        })
      ))
    )
  );

  getPmsHealthyStatus$ = createEffect(() => this.actions$.pipe(
    ofType(AppActions.DoPmsHealthCheck),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      if (practice) {
        return this.appService.doPmsHealthCheck(practice).pipe(
          map((result: boolean) => {
            return AppActions.SetPmsHealthy({healthy: result});
          })
        )
      }
      return of(AppActions.Noop());
    })
  ));

  getCurrencies$ = createEffect(() => this.actions$.pipe(
    ofType(AppActions.GetCurrencies),
    mergeMap(() => this.appService.getCurrencies()
      .pipe(
        map((result: Currency[]) => {
          return AppActions.GetCurrenciesSuccess({currencies: result.map((currency) => this.currencyAdapter.run(currency))});
        })
      ))
    )
  );

  getTags$ = createEffect(() => this.actions$.pipe(
      ofType(AppActions.GetCurrencies),
      mergeMap(() => this.appService.getTags()
        .pipe(
          map((result: TagDto[]) => {
            return AppActions.GetTagsSuccess({tags: result.map((tagDto) => this.tagAdapter.run(tagDto))});
          })
        ))
    )
  );
}
