import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { PracticeFeature } from 'src/app/enums/practice-feature';
import { practiceHasFeature } from 'src/app/helpers/practice-has-feature';
import { Patient } from 'src/app/models/Patient';
import { Practice } from 'src/app/models/Practice';
import { getCurrentPractice } from 'src/app/practices/state/selectors';
import { AppState } from 'src/app/state/reducers';
import {HistoryItem} from "../../../models/HistoryItem";
import {PMS} from "../../../enums/pms";

@Component({
  selector: 'patient-panel',
  templateUrl: './patient-panel.component.html',
  styleUrls: ['./patient-panel.component.scss']
})
export class PatientPanelComponent implements OnInit, OnDestroy {
  @Input() patient?: Patient;
  @Input() disabled?: boolean;
  @Input() shareLoading = false;
  @Input() shared = false;
  @Input() shareDisabled = false;
  @Input() changeDisabled = false;
  @Input() patientHistoryLoading = false;
  @Input() patientHistory: HistoryItem[] = [];
  @Output() patientChanged = new EventEmitter();
  @Output() shareToPms = new EventEmitter<Patient>();
  @Output() patientHistoryOpened = new EventEmitter<string>();
  alive = true;
  practice$?: Observable<Practice | null>;
  practiceSub$?: Subscription;
  practice?: Practice | null;
  shareEnabled = false;
  shareLabel = 'Share patient to PMS';

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    if (this.shareLoading === false) {
      this.shared = false;
    }
    this.subscribeToCurrentPractice();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    );

    this.practiceSub$ = this.practice$.subscribe((practice) => {
      this.practice = practice;
      this.updateShareEnabled();
      this.updateShareLabel();
    });
  }


  handlePatientChanged(): void {
    this.patientChanged.emit();
  }

  emitShareToPms(): void {
    if (this.patient && !this.disabled) {
      this.shareToPms.emit(this.patient);
    }
  }

  handlePatientHistoryOpened(patientId: string): void {
    this.patientHistoryOpened.emit(patientId);
  }

  updateShareEnabled(): void {
    this.shareEnabled = false;

    if (
      !this.shareDisabled &&
      this.practice &&
      practiceHasFeature(this.practice, PracticeFeature.CLIENT_SHARE_TO_PMS) &&
      ((this.practice.pms !== PMS.PROVET && this.practice.pms !== PMS.ASCEND) ||
        ((this.practice.pms === PMS.PROVET ||
          this.practice.pms === PMS.ASCEND) &&
          this.patient?.url))
    ) {
      this.shareEnabled = true;
    }
  }

  updateShareLabel(): void {
    this.shareLabel = 'Share patient to PMS';

    if (this.practice) {
      switch (this.practice.pms) {
        case PMS.PROVET || PMS.ASCEND:
          this.shareLabel = 'Open patient in ' + this.practice.pms;
          break;

        default:
          this.shareLabel = 'Share patient to ' + this.practice.pms;
      }
    }
  }
}
