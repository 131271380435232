import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../services/environment.service';
import {from, Observable} from 'rxjs';
import { Message } from '../models/Message';
import { map, switchMap } from 'rxjs/operators';
import { MessageDto } from '../interfaces/dto/message.dto';
import { MessageAdapter } from '../adapters/message.adapter';
import {resizeImage} from '../helpers/resize-image';
import {Media} from '../models/Media';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private messageAdapter: MessageAdapter
  ) {
  }

  getMessagesForConversation(conversationId: string): Observable<Message[]> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${conversationId}/messages`;
    return this.http.get<MessageDto[]>(url, {withCredentials: true}).pipe(
      map((response: MessageDto[]) => {
        return response.map((message) => this.messageAdapter.run(message));
      })
    );
  }

  submitFileMessage(conversationId: string, file: File, caption: string | null): Observable<void> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${conversationId}/files`;
    const formData: FormData = new FormData();
    formData.append('asset', file, file.name);
    if (caption) {
      formData.append('caption', caption);
    }
    return this.http.post(url, formData, {withCredentials: true}).pipe(
      map(() => {
        return;
      })
    );
  }

  sendFileMessage(conversationId: string, file: File, caption: string | null = null): Observable<void> {
    return from(resizeImage(file, this.environmentService.get('maxImageSize') || 800)).pipe(switchMap(resizedFile => {
      return this.submitFileMessage(conversationId, resizedFile, caption);
    }));
  }

  sendMediaMessage(conversationId: string, media: Media, caption: string): Observable<void> {
    const url = this.environmentService.get('backendUrl') + `/conversations/${conversationId}/send-media/${media.id}`;
    return this.http.post<void>(url, {caption}, {withCredentials: true}).pipe(
      map(() => {
        return;
      })
    );
  }

  deleteMessage(messageId: string): Observable<boolean> {
    const url = this.environmentService.get('backendUrl') + `/messages/${messageId}`;
    return this.http.delete<boolean>(url, {withCredentials: true}).pipe(
      map((response: boolean) => {
        return response;
      })
    );
  }
}
