export enum PracticeFeature {
    LAST_TRANSACTION = 'last-tx',
    LAST_TRANSACTION_DATE = 'last-tx-date',
    CLIENT_BALANCE = 'client-balance',
    DISPLAY_CLIENT_DEPOSIT = 'display-client-deposit',
    SHOW_TEAM_LOCATION = 'show-team-location',
    CLIENT_ACCOUNT_TYPE = 'client-account-type',
    CLIENT_SHARE_TO_PMS = 'client-share-to-pms',
    PATIENT_SHARE_TO_PMS = 'patient-share-to-pms',
    PATIENT_REMINDERS = 'patient-reminders',
    PATIENT_NOTES = 'patient-notes',
    PATIENT_HISTORY = 'patient-history',
    INSURANCE = 'insurance',
    WEIGHT = 'weight',
    CAREPLANS = 'careplans',
    PAYMENTS = 'payments',
    REFUNDS = 'refunds',
    PAYMENT_SYNCING = 'payment-syncing',
    CLIENT_NOTES = 'client-notes',
    FINANCIAL_NOTES = 'financial-notes',
    CAMPAIGNS = 'campaigns',
    SHOW_UNVERIFIED_CONVERSATIONS = 'show-unverified-conversations',
    CASH_UP = 'cash-up',
    CONVERSATION_SEND_TO_PMS = 'conversation-send-to-pms',
    CONVERSATION_DOWNLOAD_PDF = 'conversation-download-pdf',
    MESSAGE_DIRECT_NUMBERS = 'message-numbers-direct',
    FORCE_INITIAL_MESSAGE = 'force-initial-whatsapp-message',
    HOLD_AND_CAPTURE_PAYMENTS = 'hold-and-capture-payments',
    AUTOMATIC_PAYMENTS = 'automatic-payments',
    MARK_CONVERSATION_READ_ON_OPEN = 'mark-conversation-read-on-open',
    CONVERSATION_CONFETTI_EFFECT = 'conversation-confetti',
    CUSTOM_EXPIRY = 'custom-payment-expiry',
    PRODUCT_REQUESTS = 'product-requests',
    PRODUCT_REQUESTS_DISPENSED_COLUMN = 'product-requests-dispensed-column',
    DASHBOARD = 'dashboard',
    FACEBOOK_MESSENGER = 'facebook-messenger',
    INSTAGRAM = 'instagram',
    SMS_MESSAGING = 'sms-messaging',
    FORMS = 'forms',
    SHOW_PAYMENT_REQUEST_BUTTON='show-payment-request-button'
}
