<ng-container *ngIf="patient">
    <div class="client-details">
        <div class="top-row">
            <div class="name">
                <img src="assets/images/icons/icon-paw.svg" class="icon">
                <div class="text">{{ patient.name }}</div>
            </div>
            <div class="species-row">
                <div class="species">{{ patient.species }}</div>
                <ng-container *ngIf="!changeDisabled">
                  <button [disabled]="disabled" pButton label="Change matched patient" (click)="changePatient()" class="p-button-fourth p-button-sm change-patient-btn"></button>
                </ng-container>
            </div>
        </div>
        <div class="date-row">
            <div class="date">
                <span class="label">Last seen:</span>
                <ng-container *ngIf="patient.lastSeenAt">
                    <span>{{ patient.lastSeenAt | dfnsFormat:'dd/MM/yyyy' }}</span>
                </ng-container>
                <ng-container *ngIf="!patient.lastSeenAt">
                    <span>N/A</span>
                </ng-container>
            </div>
        </div>
        <div class="details-row">
          <ng-container *ngIf="!patient.incomplete">
            <div class="detail">
              <div class="label">Age</div>
              <div class="value">{{ patient.age || '-' }}</div>
            </div>
            <ng-container *ngIf="weightEnabled">
              <div class="detail">
                  <div class="label">Weight</div>
                  <div class="value">{{ patient.weight || '-' }}</div>
              </div>
            </ng-container>
            <div class="detail">
              <div class="label">Sex</div>
              <div class="value">{{ patient.sex || '-' }}</div>
            </div>
            <div class="detail">
              <div class="label">Breed</div>
              <div class="value">{{ patient.breed || '-' }}</div>
            </div>
            <ng-container *ngIf="insuranceEnabled">
                <div class="detail">
                    <div class="label">Insurance</div>
                    <div class="value">{{ patient.insurance || 'Not on file' }}</div>
                </div>
            </ng-container>
            <ng-container *ngIf="careplansEnabled">
              <div class="detail">
                <div class="label">Care Plan</div>
                <div class="value">
                  <ng-container *ngIf="!patient.careplan || (isArray(patient.careplan) && patient.careplan.length === 0)">
                    -
                  </ng-container>
                  <ng-container *ngIf="patient.careplan && isArray(patient.careplan) && patient.careplan.length > 1">
                    <patient-careplans [careplans]="getCareplans()"></patient-careplans>
                  </ng-container>
                  <ng-container *ngIf="patient.careplan && (!isArray(patient.careplan) || (isArray(patient.careplan) && patient.careplan.length === 1))">
                    {{ patient.careplan }}
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="patient.incomplete">
            <div class="error">We're currently having trouble loading this patient's data from your PMS.</div>
          </ng-container>
        </div>
    </div>
</ng-container>
