import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/reducers';
import * as FormActions from './actions';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of, takeUntil} from 'rxjs';
import {CloseFormRequest, GetMoreSendFormPatients, GetSendFormPatientsSuccess, SearchForms} from "./actions";
import * as PatientActions from "../../patients/state/actions";
import {getPatientPage} from "../../patients/state/selectors";
import {getConversationClient} from "../../conversation/state/selectors";
import {Client} from "../../models/Client";
import {Patient} from "../../models/Patient";
import * as ConversationActions from "../../conversation/state/actions";
import {getCurrentPractice} from "../../practices/state/selectors";
import {FormsService} from "../forms.service";
import {Form} from "../interfaces/form";
import {Router} from "@angular/router";
import {Noop} from "../../state/actions";
import {MessageService} from "primeng/api";
import * as ProductRequestActions from "../../product-requests/state/actions";
import {ClientService} from "../../clients/client.service";
import {getProductRequestClient} from "../../product-requests/state/selectors";
import {getFormRequestClient} from "./selectors";
import {FormSubmission} from "../interfaces/form-submission";
import {Contact} from "../../models/Contact";
import {Channel} from "../../enums/channel";

@Injectable()
export class FormsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private formsService: FormsService,
    private router: Router,
    private messageService: MessageService,
    private clientService: ClientService
  ) {
  }

  openFormRequest$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.OpenFormRequest),
    switchMap((action) => {
      return of(
        FormActions.SetFormRequestClient({client: action.client}),
        FormActions.SetFormRequestContact({contact: action.contact}),
        FormActions.SetFormRequestChannel({channel: action.channel})
      );
    })
  ));

  closeFormRequest$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.CloseFormRequest),
    switchMap((action) => {
      return of(
        FormActions.SetFormRequestClient({}),
        FormActions.SetFormRequestContact({}),
        FormActions.SetFormRequestChannel({})
      );
    })
  ));

  getPatients$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.SetFormRequestClient),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      if (action.client && practice) {
        return this.clientService.getClientByPmsId(action.client.pmsId, practice.id, 0).pipe(
          map((result: { client: Client, patients: Patient[] }) => {
            return FormActions.GetSendFormPatientsSuccess({patients: result.patients, replace: true});
          })
        );
      }

      return of(FormActions.GetSendFormPatientsSuccess({patients: [], replace: true}));
    })
  ));

  getMorePatients$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.GetMoreSendFormPatients),
    withLatestFrom(this.store.select(getCurrentPractice), this.store.select(getFormRequestClient)),
    mergeMap(([action, practice, client]) => {
      if (client && practice) {
        return this.clientService.getClientByPmsId(client.pmsId, practice.id, action.page).pipe(
          map((result: { client: Client, patients: Patient[] }) => {
            return FormActions.GetSendFormPatientsSuccess({patients: result.patients, replace: false});
          })
        );
      }

      return of(FormActions.GetSendFormPatientsSuccess({patients: [], replace: true}));
    })
  ));

  searchForms$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.SearchForms),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.searchForms(practice, action.search).pipe(
        map((result: Form[]) => {
          return FormActions.SearchFormsSuccess({forms: result});
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when getting forms',
            life: 5000
          });
          return of(FormActions.SearchFormsFailed());
        }),
      );
    })
  ));

  getForm$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.GetForm),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.getForm(practice, action.formId).pipe(
        map((result: Form) => {
          return FormActions.GetFormSuccess({form: result});
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when getting this form',
            life: 5000
          });
          return of(FormActions.GetFormFailed());
        }),
      );
    })
  ));

  getFormToSend$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.GetFormToSend),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.getForm(practice, action.formId).pipe(
        map((result: Form) => {
          return FormActions.GetFormToSendSuccess({form: result});
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when getting this form',
            life: 5000
          });
          return of(Noop());
        }),
      );
    })
  ));

  createForm$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.CreateForm),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.createForm(practice, action.dto).pipe(
        map((result: Form) => {
          return FormActions.CreateFormSuccess({form: result});
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when creating this form',
            life: 5000
          });
          return of(FormActions.CreateFormFailed());
        }),
      );
    })
  ));

  createFormSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.CreateFormSuccess),
    tap((action) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Form created successfully',
        life: 5000
      });
      this.router.navigateByUrl('/forms');
    }),
    map((action) => {
      return Noop();
    })
  ));

  updateForm$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.UpdateForm),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.updateForm(practice, action.dto).pipe(
        map((result: Form) => {
          return FormActions.UpdateFormSuccess({form: result});
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when updating this form',
            life: 5000
          });
          return of(FormActions.UpdateFormFailed());
        }),
      );
    })
  ));

  updateFormSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.UpdateFormSuccess),
    tap((action) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Form updated successfully',
        life: 5000
      });
      this.router.navigateByUrl('/forms');
    }),
    map((action) => {
      return Noop();
    })
  ));

  archiveForm$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.ArchiveForm),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.archiveForm(practice, action.formId, action.archived).pipe(
        map((result: Form) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: action.archived ? 'Form archived successfully' : 'Form restored successfully',
            life: 5000
          });
          return FormActions.ArchiveFormSuccess({form: result});
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when archiving this form',
            life: 5000
          });
          return of(FormActions.ArchiveFormFailed());
        }),
      );
    })
  ));

  createFormSubmission$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.CreateFormSubmission),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.createFormSubmission(practice, action.dto).pipe(
        map((result: FormSubmission) => {
          return FormActions.CreateFormSubmissionSuccess({formSubmission: result, client: action.client, contact: action.contact, channel: action.channel});
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when sending this form',
            life: 5000
          });
          return of(FormActions.CreateFormSubmissionFailed());
        }),
      );
    })
  ));

  createFormSubmissionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.CreateFormSubmissionSuccess),
    map((action) => {
      return FormActions.SendFormSubmission({formSubmission: action.formSubmission, client: action.client, contact: action.contact, channel: action.channel});
    })
  ));

  sendFormSubmission$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.SendFormSubmission),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.sendFormSubmission(practice, action.formSubmission, action.client, action.contact, action.channel).pipe(
        map((result: boolean) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Form sent successfully',
            life: 5000
          });
          return FormActions.SendFormSubmissionSuccess();
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when sending this form',
            life: 5000
          });
          return of(FormActions.SendFormSubmissionFailed());
        }),
      );
    })
  ));

  sendFormSubmissionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.SendFormSubmissionSuccess),
    map((action) => {
      return FormActions.CloseFormRequest();
    })
  ));

  sendFormSubmissionFailed$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.SendFormSubmissionFailed),
    map((action) => {
      return FormActions.CloseFormRequest();
    })
  ));

  getFormSubmissions$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.GetFormSubmissions),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.getFormSubmissions(practice, action.page, action.perPage, action.searchString).pipe(
        map((result: { formSubs: FormSubmission[], totalCount: number }) => {
          return FormActions.GetFormSubmissionsSuccess({formSubmissions: result.formSubs, totalCount: result.totalCount});
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when getting form submissions',
            life: 5000
          });
          return of(FormActions.GetFormSubmissionsFailed());
        }),
      );
    })
  ));

  getFormSubmission$ = createEffect(() => this.actions$.pipe(
    ofType(FormActions.GetFormSubmission),
    withLatestFrom(this.store.select(getCurrentPractice)),
    mergeMap(([action, practice]) => {
      return this.formsService.getFormSubmission(practice, action.uuid).pipe(
        map((result: FormSubmission) => {
          return FormActions.GetFormSubmissionSuccess({formSubmission: result});
        }),
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred when getting this form submission',
            life: 5000
          });
          return of(FormActions.GetFormSubmissionFailed());
        }),
      );
    })
  ));
}
