import * as actions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Practice } from 'src/app/models/Practice';
import { User } from 'src/app/models/User';
import { Group } from '../../models/Group';
import {Template} from '../../interfaces/template';
import { PracticeConfig } from '../../enums/practice-config';
import { PracticeConfigInterface } from '../../interfaces/practice-config.interface';

export interface PracticesState {
    currentPractice: Practice | null;
    availablePractices: Practice[] | null;
    staff: User[];
    groups: Group[];
    templates: Template[];
    config: PracticeConfigInterface | null;
}

export const initialState: PracticesState = {
    currentPractice: null,
    availablePractices: null,
    staff: [],
    groups: [],
    templates: [],
    config: null
};

const practicesReducer = createReducer(
  initialState,
  on(actions.GetPracticeStaffSuccess, (state, payload) => ({
    ...state,
    staff: payload.users
  })),
  on(actions.GetPracticeGroupsSuccess, (state, payload) => ({
    ...state,
    groups: payload.groups
  })),
  on(actions.GetUserPracticesSuccess, (state, payload) => ({
    ...state,
    availablePractices: payload.practices
  })),
  on(actions.SetSelectedPractice, (state, payload) => ({
    ...state,
    currentPractice: payload.practice
  })),
  on(actions.ClearAvailablePractices, (state, payload) => ({
    ...state,
    availablePractices: null
  })),
  on(actions.GetPracticeTemplatesSuccess, (state, payload) => ({
    ...state,
    templates: payload.templates
  })),
  on(actions.GetPracticeConfigSuccess, (state, payload) => ({
    ...state,
    config: payload.config
  }))
);

export function reducer(state: PracticesState | undefined, action: Action): PracticesState {
  return practicesReducer(state, action);
}
