import {Injectable} from '@angular/core';
import {EnvironmentService} from '../services/environment.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Form} from './interfaces/form';
import {Practice} from '../models/Practice';
import {FormCreateDto} from './interfaces/dto/form-create.dto';
import {FormUpdateDto} from './interfaces/dto/form-update.dto';
import {FormSubmissionCreateDto} from './interfaces/dto/form-submission-create.dto';
import { FormSubmission } from './interfaces/form-submission';
import {Client} from '../models/Client';
import {Contact} from '../models/Contact';
import {Channel} from '../enums/channel';

@Injectable({
  providedIn: 'root'
})
export class FormsService {
  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
  ) {}

  searchForms(practice: Practice | null, search?: string): Observable<Form[]> {
    if (!practice) {
      throw new Error('No practice set');
    }

    let url = this.environmentService.get('formsApiUrl') + `/search?practiceId=${practice.coreId}`;

    if (search) {
      url += `&search=${search}`;
    }

    return this.http.get<Form[]>(url).pipe(
      map((response: Form[]) => {
        return response;
      })
    );
  }

  createForm(practice: Practice | null, dto: FormCreateDto): Observable<Form> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url = this.environmentService.get('formsApiUrl') + `/create?practiceId=${practice.coreId}`;

    return this.http.post<Form>(url, dto).pipe(
      map((response: Form) => {
        return response;
      })
    );
  }

  updateForm(practice: Practice | null, dto: FormUpdateDto): Observable<Form> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url = this.environmentService.get('formsApiUrl') + `/${dto.id}?practiceId=${practice.coreId}`;

    return this.http.patch<Form>(url, dto).pipe(
      map((response: Form) => {
        return response;
      })
    );
  }

  archiveForm(practice: Practice | null, formId: number, archived: boolean): Observable<Form> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url = this.environmentService.get('formsApiUrl') + `/${formId}/archive?practiceId=${practice.coreId}`;

    return this.http.post<Form>(url, {
      archived
    }).pipe(
      map((response: Form) => {
        return response;
      })
    );
  }

  getForm(practice: Practice | null, formId: number): Observable<Form> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url = this.environmentService.get('formsApiUrl') + `/${formId}?practiceId=${practice.coreId}`;

    return this.http.get<Form>(url).pipe(
      map((response: Form) => {
        return response;
      })
    );
  }

  createFormSubmission(practice: Practice | null, dto: FormSubmissionCreateDto): Observable<FormSubmission> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url = this.environmentService.get('formsApiUrl') + `/submissions/create?practiceId=${practice.coreId}`;

    return this.http.post<FormSubmission>(url, dto).pipe(
      map((response: FormSubmission) => {
        return response;
      })
    );
  }

  sendFormSubmission(practice: Practice | null, formSubmission: FormSubmission, client: Client, contact: Contact, channel: Channel): Observable<boolean> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url = this.environmentService.get('backendUrl') + `/forms/send?practiceId=${practice.id}`;

    return this.http.post<boolean>(url, {
      uuid: formSubmission.uuid,
      client,
      contact,
      channel,
      practiceId: practice.id,
      formName: formSubmission.form.name,
    }).pipe(
      map((response: boolean) => {
        return response;
      })
    );
  }

  getFormSubmissions(practice: Practice | null, page: number, perPage: number, searchString: string | null): Observable<{ formSubs: FormSubmission[], totalCount: number }> {
    if (!practice) {
      throw new Error('No practice set');
    }

    let url = this.environmentService.get('formsApiUrl') + `/submissions/search?practiceId=${practice.coreId}&page=${page}&perPage=${perPage}`;

    if (searchString && searchString !== '') {
      url += `&searchString=${encodeURIComponent(searchString || '')}`;
    }

    return this.http.get<{ formSubs: FormSubmission[], totalCount: number }>(url).pipe(
      map((response: { formSubs: FormSubmission[], totalCount: number }) => {
        return response;
      })
    );
  }

  getFormSubmission(practice: Practice | null, uuid: string): Observable<FormSubmission> {
    if (!practice) {
      throw new Error('No practice set');
    }

    const url = this.environmentService.get('formsApiUrl') + `/submissions/${uuid}?practiceId=${practice.coreId}`;

    return this.http.get<FormSubmission>(url).pipe(
      map((response: FormSubmission) => {
        return response;
      })
    );
  }
}
