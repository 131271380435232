import {Injectable} from '@angular/core';
import {Channel} from '../enums/channel';
import {MessageStatus} from '../enums/message-status';
import {MessageType} from '../enums/message-type';
import {CampaignMessageDto} from '../interfaces/dto/campaign-message.dto';
import {CampaignMessage} from '../models/CampaignMessage';
import {ReadStatus} from '../enums/read-status';

@Injectable({
  providedIn: 'root'
})
export class CampaignMessageAdapter {
  constructor() {
  }

  run(dto: CampaignMessageDto): CampaignMessage {
    return {
        ...dto,
        id: '1',
        author: undefined,
        status: dto.status as MessageStatus,
        channel: dto.channel as Channel,
        resolvedAt: new Date(dto.resolvedAt),
        createdAt: new Date(dto.resolvedAt),
        attachments: dto.attachments ? dto.attachments : [],
        type: MessageType.STANDARD,
        readStatus: ReadStatus.READ,
        summary: '',
        buttons: dto.buttons,
    };
  }
}
