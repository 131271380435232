import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from 'src/app/state/reducers';
import {GetUnreadHelpPosts} from '../../../auth/state/actions';
import {getUser} from '../../../auth/state/selectors';
import {takeWhile} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EnvironmentService} from '../../../services/environment.service';
import {getUnreadNotificationCount} from '../../../notifications/state/selectors';
import {User} from '../../../models/User';
import {getCurrentPractice} from '../../../practices/state/selectors';
import {Practice} from '../../../models/Practice';
import {AuthService} from '../../../auth/auth.service';
import {isNewPaymentRequestOpen} from '../../../payments/state/selectors';
import {CloseNewPaymentRequest, OpenNewPaymentRequest} from '../../../payments/state/actions';
import {isNewCampaignOpen} from '../../../campaigns/state/selectors';
import {CloseNewCampaign, OpenNewCampaign} from '../../../campaigns/state/actions';
import {isPmsHealthy} from 'src/app/state/selectors';
import {DialogService} from 'primeng/dynamicdialog';
import {OpenNewConversation} from '../../../dialogs/state/actions';
import {isCampaignsEnabled} from '../../../helpers/campaigns-enabled';
import {practiceHasFeature} from '../../../helpers/practice-has-feature';
import {PracticeFeature} from '../../../enums/practice-feature';
import {Role} from "../../../enums/role";
import {isMessagingEnabled} from "../../../helpers/is-messaging-enabled";
import {pickTextColorBasedOnBgHelper} from "../../../helpers/pick-text-color-based-on-bg.helper";

@Component({
  selector: 'desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss'],
  providers: [
    DialogService
  ]
})
export class DesktopHeaderComponent implements OnInit, OnDestroy {
  alive = true;
  pageTitle = '';
  notificationCount$?: Observable<number>;
  user$?: Observable<User | null>;
  user?: User;
  practice$?: Observable<Practice | null>;
  practice?: Practice;
  showingCreateActions = false;
  helpLink = '';
  profileLink = '';
  settingsLink = '';
  notificationsOpen = false;
  userMenuOpen = false;
  showPracticeContactDetails = false;
  paymentRequestOpen$?: Observable<boolean>;
  paymentRequestOpen = false;
  newCampaignOpen$?: Observable<boolean>;
  newCampaignOpen = false;
  pmsHealthy$?: Observable<boolean>;
  searchDisabled = true;
  messagingDisabled = false;
  accentColor?: string;
  headerTextColor?: string;

  constructor(
    private store: Store<AppState>,
    private environmentService: EnvironmentService,
    private authService: AuthService
  ) {

  }

  ngOnInit(): void {
    this.subscribeToNotificationCount();
    this.subscribeToCurrentUser();
    this.subscribeToCurrentPractice();
    this.subscribeToPaymentRequestOpen();
    this.subscribeToNewCampaignOpen();
    this.subscribeToPmsHealthy();
    this.getHelpLink();
    this.getProfileLink();
    this.getSettingsLink();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  getHelpLink(): void {
    this.helpLink = `${this.environmentService.get('helpUrl')}/learn/section/digital-practice`;
  }

  getProfileLink(): void {
    this.profileLink = `${this.environmentService.get('authUrl')}/user/profile`;
  }

  subscribeToNotificationCount(): void {
    this.notificationCount$ = this.store.pipe(select(getUnreadNotificationCount)).pipe(
      takeWhile(() => this.alive)
    );
  }

  subscribeToCurrentUser(): void {
    this.user$ = this.store.pipe(select(getUser)).pipe(
      takeWhile(() => this.alive)
    );

    this.user$.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }

  subscribeToCurrentPractice(): void {
    this.practice$ = this.store.pipe(select(getCurrentPractice)).pipe(
      takeWhile(() => this.alive)
    );

    this.practice$.subscribe(practice => {
      if (practice) {
        this.practice = practice;
        this.accentColor = '#' + practice.accentColor;
        this.headerTextColor = pickTextColorBasedOnBgHelper(this.accentColor, '#FFFFFF', '#4F4F4F');
        this.updateSearchHidden();
        this.updateMessagingDisabled();
      }
    });
  }

  getSettingsLink(): void {
    this.settingsLink = `${this.environmentService.get('authUrl')}/user/notifications`;
  }

  toggleCreateActions(): void {
    this.showingCreateActions = !this.showingCreateActions;
  }

  handleGoToHelpCenter(): void {
    setTimeout(() => {
      this.store.dispatch(GetUnreadHelpPosts());
    }, 5000);
  }

  toggleNotifications(): void {
    this.notificationsOpen = !this.notificationsOpen;
  }

  toggleUserMenu(): void {
    this.userMenuOpen = !this.userMenuOpen;
  }

  closeUserMenu(): void {
    this.userMenuOpen = false;
  }

  togglePracticeContactDetails(): void {
    this.showPracticeContactDetails = !this.showPracticeContactDetails;
  }

  closePracticeContactDetails(): void {
    this.showPracticeContactDetails = false;
  }

  subscribeToPaymentRequestOpen(): void {
    this.paymentRequestOpen$ = this.store.pipe(select(isNewPaymentRequestOpen)).pipe(takeWhile(() => this.alive));

    this.paymentRequestOpen$.subscribe((open) => {
      this.paymentRequestOpen = open;
    });
  }

  subscribeToNewCampaignOpen(): void {
    this.newCampaignOpen$ = this.store.pipe(select(isNewCampaignOpen)).pipe(takeWhile(() => this.alive));

    this.newCampaignOpen$.subscribe((open) => {
      this.newCampaignOpen = open;
    });
  }

  subscribeToPmsHealthy(): void {
    this.pmsHealthy$ = this.store.pipe(select(isPmsHealthy)).pipe(takeWhile(() => this.alive));
  }

  updateMessagingDisabled(): void {
    if (this.practice) {
      if (this.practice.messagingEnabled) {
        this.messagingDisabled = false;
      } else {
        this.messagingDisabled = true;
      }
    }
  }

  updateSearchHidden(): void {
    if (this.practice) {
      if (this.practice.userRole === Role.ANALYST) {
        this.searchDisabled = true;
      } else {
        this.searchDisabled = false;
      }
    }
  }

  logout(): void {
    if (this.user) {
      this.authService.logout();
    }
  }

  handleActionSelected(): void {
    this.showingCreateActions = false;
  }

  handleNewPaymentDialogClosed(): void {
    this.store.dispatch(CloseNewPaymentRequest());
  }

  closeCampaignDialog(): void {
    this.store.dispatch(CloseNewCampaign());
  }
}
