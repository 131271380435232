<div class="form-selector">
  <ng-container *ngIf="loading">
    <p-progressSpinner [style]="{width: '40px', height: '40px', margin: '40px auto', display: 'block'}"
                       styleClass="custom-spinner" strokeWidth="4"></p-progressSpinner>
  </ng-container>
  <ng-container *ngIf="!loading">
    <ng-container *ngFor="let form of forms">
      <div class="form-selector-item">
        <div class="name">{{ form.name }}</div>
        <div class="select">
          <button pButton label="Select" class="p-button-sm p-button-ghost" (click)="selectForm(form)"></button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
