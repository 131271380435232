import * as actions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Notification } from 'src/app/models/Notification';

export interface NotificationsState {
    notifications: Notification[];
    notificationsTotalCount: number;
    visibleNotifications: Notification[];
}

export const initialState: NotificationsState = {
    notifications: [],
    notificationsTotalCount: 0,
    visibleNotifications: []
};

const notificationsReducer = createReducer(
  initialState,
  on(actions.AddNotification, (state, payload) => ({
    ...state,
    notifications: [
        ...state.notifications,
        payload.notification
    ],
    notificationsTotalCount: (state.notificationsTotalCount + 1)
  })),
  on(actions.ShowNotification, (state, payload) => ({
    ...state,
    visibleNotifications: [
        ...state.visibleNotifications,
        payload.notification
    ]
  })),
  on(actions.HideNotification, (state, payload) => ({
    ...state,
    visibleNotifications: [
      ...state.visibleNotifications.filter(notification => {
        return payload.notification.id !== notification.id;
      }),
    ]
  })),
  on(actions.GetNotificationsSuccess, (state, payload) => ({
    ...state,
    notifications: payload.replace ? payload.notifications : [
      ...state.notifications,
      ...payload.notifications
    ],
    notificationsTotalCount: payload.totalCount
  })),
  on(actions.MarkNotificationAsUnread, (state, payload) => ({
    ...state,
    notifications: [
        ...state.notifications.filter(notification => notification.id !== payload.notification.id),
        {
          ...payload.notification,
          read: false
        }
    ]
  })),
  on(actions.MarkNotificationAsRead, (state, payload) => ({
    ...state,
    notifications: [
        ...state.notifications.filter(notification => notification.id !== payload.notification.id),
        {
          ...payload.notification,
          read: true
        }
    ]
  })),
  on(actions.MarkAllNotificationsAsRead, (state) => ({
    ...state,
    notifications: state.notifications.map(notification => {
      return {
        ...notification,
        read: true
      };
    })
  })),
);

export function reducer(state: NotificationsState | undefined, action: Action): NotificationsState {
  return notificationsReducer(state, action);
}
