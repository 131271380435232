import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {map, takeWhile} from 'rxjs/operators';
import { CampaignAdapter } from '../adapters/campaign.adapter';
import { Channel } from '../enums/channel';
import { CampaignAddDto } from '../interfaces/dto/campaign-add.dto';
import { CampaignDto } from '../interfaces/dto/campaign.dto';
import { Campaign } from '../models/Campaign';
import { Practice } from '../models/Practice';
import { EnvironmentService } from '../services/environment.service';
import {CampaignEditDto} from "../interfaces/dto/campaign-edit.dto";
import {UserDto} from "../interfaces/dto/user.dto";
import {UserAdapter} from "../adapters/user.adapter";
import {User} from "../models/User";
import {prettifyTemplateContent} from "../helpers/prettify-template-content";
import {MergeFieldContent} from "../enums/merge-field-content";
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  constructor(private environmentService: EnvironmentService, private http: HttpClient, private campaignAdapter: CampaignAdapter, private userAdapter: UserAdapter, private messageService: MessageService) { }

  getCampaigns(practice: Practice): Observable<Campaign[]> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practice.id}/campaigns`;
    return this.http.get<CampaignDto[]>(url, {withCredentials: true}).pipe(
      map((response: CampaignDto[]) => {
        return response.map(campaign => this.campaignAdapter.run(campaign));
      })
    );
  }

  addCampaign(campaign: CampaignAddDto): Observable<{
    campaigns: Campaign[],
    invalidNumbers?: string[],
    duplicates?: string[],
    optedOutUsers: User[],
    dataErrors?: {name: string, errors: number}[]
  }> {
    const url = this.environmentService.get('backendUrl') + `/practices/${campaign.practiceId}/campaigns`;
    return this.http.post<{
      campaigns: CampaignDto[],
      invalidNumbers?: string[],
      duplicates?: string[],
      optedOutUsers: UserDto[],
      dataErrors?: {name: string, errors: number}[]
    }>(url, campaign, {withCredentials: true}).pipe(
      map((response: {
        campaigns: CampaignDto[],
        invalidNumbers?: string[],
        duplicates?: string[],
        optedOutUsers: UserDto[],
        dataErrors?: {name: string, errors: number}[]
      }) => {
        return {
          campaigns: response.campaigns.map(campaignDto => this.campaignAdapter.run(campaignDto)),
          invalidNumbers: response.invalidNumbers,
          duplicates: response.duplicates,
          optedOutUsers: response.optedOutUsers.map(userDto => this.userAdapter.run(userDto)),
          dataErrors: response.dataErrors
        };
      })
    );
  }

  editCampaign(campaignDto: CampaignEditDto): Observable<Campaign[]> {
    const url = this.environmentService.get('backendUrl') + `/practices/${campaignDto.practiceId}/campaigns/${campaignDto.id}`;
    return this.http.patch<CampaignDto[]>(url, campaignDto, {withCredentials: true}).pipe(
      map((response: CampaignDto[]) => {
        return response.map(campaign => this.campaignAdapter.run(campaign))
      })
    );
  }

  deleteCampaign(campaignId: string, practiceId: string): Observable<string> {
    const url = this.environmentService.get('backendUrl') + `/practices/${practiceId}/campaigns/${campaignId}`;
    return this.http.delete<boolean>(url, {withCredentials: true}).pipe(
      map((response) => {
        return campaignId;
      })
    );
  }

  exportCampaignAsCsv(campaignId: string, practiceId: string): Observable<Blob> {
    const url = this.environmentService.get('backendUrl') + `/campaigns/${campaignId}/export-csv/${practiceId}?practiceId=${practiceId}`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
