import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {catchError, filter, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import { AuthState } from 'src/app/auth/state/reducer';
import { getUser } from 'src/app/auth/state/selectors';
import { Notification } from 'src/app/models/Notification';
import { Noop } from 'src/app/state/actions';
import { AppState } from 'src/app/state/reducers';
import { NotificationService } from '../notification.service';
import * as NotificationActions from './actions';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService
  ) {}

  getNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationActions.GetNotifications),
    withLatestFrom(this.store.select(getUser)),
    mergeMap(([action, user]) => {
      if (user) {
        return this.notificationService.getNotifications(Number(user.id), action.page, action.perPage, action.unreadOnly).pipe(
          map((result: {notifications: Notification[], count: number}) => {
            return NotificationActions.GetNotificationsSuccess({notifications: result.notifications, totalCount: result.count, replace: !!(action.page === 0)});
          }),
          catchError((e) => {
            throw new Error(e);
          })
        );
      }

      return of(Noop());
    }))
  );

  markAsRead$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationActions.MarkNotificationAsRead),
    mergeMap((action) => {
      return this.notificationService.markAsRead(action.notification.id).pipe(
        map(success => success))
    })), {dispatch: false});

  markAsUnread$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationActions.MarkNotificationAsUnread),
    mergeMap((action) => {
      return this.notificationService.markAsUnread(action.notification.id).pipe(
        map(success => success))
    })), {dispatch: false});

  markAllAsRead$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationActions.MarkAllNotificationsAsRead),
    withLatestFrom(this.store.select(getUser)),
    mergeMap(([action, user]) => {
      if (user) {
        return this.notificationService.markAllAsRead(Number(user.id)).pipe(
          map(success => success))
      }
      return of(false);
    })), {dispatch: false});
}
