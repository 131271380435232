<button pButton label="Client notes" class="p-button-fourth p-button-sm" [disabled]="disabled" (click)="showModal()"></button>
<ng-container *ngIf="!disabled && client">
    <p-dialog
        header="Client Notes"
        [(visible)]="modalActive"
        [modal]="true"
        [style]="{width: '500px', 'min-height': '50vh'}"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
    >
      <ng-container *ngIf="client.clientNotes && notesEnabled">
        <div [innerHtml]="client.clientNotes | nl2br"></div>
      </ng-container>
      <ng-container *ngIf="client.clientNotes && client.financialNotes && notesEnabled && financialEnabled">
        <br>
      </ng-container>
      <ng-container *ngIf="client.financialNotes && financialEnabled">
        <div [innerHtml]="client.financialNotes | nl2br"></div>
      </ng-container>
    </p-dialog>
</ng-container>
