import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ClientSearchPlaceholder } from 'src/app/enums/client-search-placeholder';
import { PMS } from 'src/app/enums/pms';
import { Client } from 'src/app/models/Client';
import { getCurrentPractice } from 'src/app/practices/state/selectors';
import { AppState } from 'src/app/state/reducers';
import { GetClientSearchResults, StartClientSearchLoading } from '../../../clients/state/actions';
import { getClients } from '../../../clients/state/selectors';

@Component({
  selector: 'client-selector',
  templateUrl: './client-selector.component.html',
  styleUrls: ['./client-selector.component.scss']
})
export class ClientSelectorComponent implements OnInit, OnDestroy, OnChanges, AfterContentInit {
  alive = true;
  @Output() clientSelected = new EventEmitter<Client>();
  @Input() disabled?: boolean;
  @Input() searchString?: string;
  @Input() noModal?: boolean;
  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;
  @ViewChild('searchInputModal') searchElementModal?: ElementRef<HTMLInputElement>;
  clients$?: Observable<Client[]>;
  searchInProgress = false;
  searched = false;
  placeholder = '';
  validateSearchIsPhoneOrEmail = false;
  searchValid = false;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscribeToClients();
    this.subscribeToCurrentPractice();
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.searchInput?.nativeElement.focus();
    }, 0);
  }

  ngOnChanges(): void {
    this.validateSearchInput();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  focus(): void {
    setTimeout(() => {
        this.searchElementModal?.nativeElement.focus();
    }, 1);
  }

  doSearch(): void {
    if (this.searchString && this.searchValid) {
      this.searched = true;
      this.searchInProgress = true;

      let searchStr = this.searchString;
      if (/^([0-9 ]+)$/.test(searchStr)) {
        searchStr = searchStr.replace(/ /g, '');
      }

      this.store.dispatch(StartClientSearchLoading());
      this.store.dispatch(GetClientSearchResults({searchString: encodeURIComponent(searchStr)}));
    }
  }

  validateSearchInput(): void {
    setTimeout(() => {
     this.searchValid = this.isSearchValid();
    }, 10);
  }

  subscribeToClients(): void {
    this.clients$ = this.store.pipe(select(getClients)).pipe(takeWhile(() => this.alive));
  }

  subscribeToCurrentPractice(): void {
    this.store.pipe(select(getCurrentPractice)).pipe(takeWhile(() => this.alive)).subscribe(practice => {
      if (practice?.pms === PMS.RXWORKS) {
        this.placeholder = ClientSearchPlaceholder.RXWORKS;
      } else if (practice?.pms === PMS.ROBOVET) {
        this.placeholder = ClientSearchPlaceholder.ROBOVET;
        this.validateSearchIsPhoneOrEmail = true;
      } else if (practice?.pms === PMS.MERLIN) {
        this.placeholder = ClientSearchPlaceholder.MERLIN;
      } else if (practice?.pms === PMS.TELEOS) {
        this.placeholder = ClientSearchPlaceholder.TELEOS;
      } else if (practice?.pms === PMS.PROVET) {
        this.placeholder = ClientSearchPlaceholder.PROVET;
      }
    });
  }

  handleClientSelected(client: Client): void {
    this.clientSelected.emit(client);
  }

  handleCancel(): void {
    this.searchInProgress = false;
  }

  isSearchValid(): boolean {
    if (!this.searchString) {
      return false;
    }

    return !!(this.searchString.replace(/\s+/g, '').length >= 3);
  }
}
