import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { MediaType } from '../../../enums/media-type';
import { MergeFieldContent } from '../../../enums/merge-field-content';
import { MediaSelectorComponent } from '../../../media/components/media-selector/media-selector.component';
import { Client } from '../../../models/Client';
import { Media } from '../../../models/Media';
import { Template } from '../../../models/Template';
import { EnvironmentService } from '../../../services/environment.service';
import { AppState } from '../../../state/reducers';
import { TemplateType } from '../../../enums/template-type';
import { ConversationService } from '../../services/conversation.service';
import { TemplateMergeField } from '../../../models/TemplateMergeField';
import { Patient } from "../../../models/Patient";

@Component({
  selector: 'replace-placeholder',
  templateUrl: './replace-placeholder.component.html',
  styleUrls: ['./replace-placeholder.component.scss'],
  providers: [DialogService],
})
export class ReplacePlaceholderComponent implements OnInit, OnDestroy {
  templateType = TemplateType;
  buttonClass = '';
  template?: Template;
  manualPlaceholders?: {id: number, label: string}[] = [];
  client?: Client;
  patient?: Patient;
  placeholdersForm = new UntypedFormGroup({});
  buttonLinkForm = new UntypedFormGroup({
    buttonLink: new UntypedFormControl('', Validators.required)
  });
  selectMediaDialog?: DynamicDialogRef;
  previewSrc: string | null = null;
  previewType = 'image';
  selectedMedia?: Media;
  mediaName = '';
  alive = true;
  mediaError: string | null = null;
  mediaType = MediaType;
  selectedMediaType: MediaType | null = null;
  mediaTypes: MediaType[] = Object.values(MediaType);
  error = false;
  result:
    | {
        template: Template;
        placeholders: { placeholderId: string; replacement: string }[];
        media?: Media;
        previewType?: string;
        buttonLink?: string;
      }
    | undefined;
    device = 'desktop';
    previewOpen = false;
    helpLink = '';

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private store: Store<AppState>,
    public dialogService: DialogService,
    public environmentService: EnvironmentService,
    private conversationService: ConversationService
  ) {
    this.template = this.config.data.template;
    this.manualPlaceholders = this.config.data.manualPlaceholders;
    this.client = this.config.data.client;
    this.patient = this.config.data.patient;
    this.buttonClass = this.config.data.buttonClass;

    if (this.template?.defaultMedia) {
      this.setSelectedMedia(this.template.defaultMedia);
    }
  }

  ngOnInit(): void {
    this.initialisePlaceholders();
    this.getHelpLink();
  }

  getHelpLink(): void {
    this.helpLink = `${this.environmentService.get('helpUrl')}/learn/section/digital-practice`;
  }

  initialisePlaceholders(): void {
    if (this.manualPlaceholders) {
      this.placeholdersForm = new UntypedFormGroup({});
      this.manualPlaceholders.forEach((p) => {
        let value = '';
        switch (p.label) {
          case MergeFieldContent.ClientName:
            value = this.client?.fullName ?? '';
            break;
          case MergeFieldContent.ClientPostcode:
            value = this.client?.postcode ?? '';
            break;
          case MergeFieldContent.ClientBalance:
            value = this.client?.accountStatus ?? '';
            break;
          case MergeFieldContent.PatientName:
            value = this.patient?.name ?? '';
            break;
        }

        this.placeholdersForm.addControl(
          p.id.toString(),
          new UntypedFormControl(value, Validators.required)
        );
      });
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  selectMedia(): void {
    if (this.template) {
      this.selectMediaDialog = this.dialogService.open(MediaSelectorComponent, {
        header: 'Select Media',
        modal: true,
        width: '1000px',
        baseZIndex: 10000,
        data: {
          type: this.template.mediaType,
        },
      });

      this.selectMediaDialog.onClose.subscribe((media: Media) => {
        if (media) {
          this.setSelectedMedia(media);
        }
      });
    }
  }

  setSelectedMedia(media: Media): void {
    this.previewSrc = null;
    this.selectedMedia = media;

    if (media.mime.includes('image/')) {
      this.previewType = 'image';
      this.previewSrc = media.signedUrl;
    } else if (media.mime.includes('video/')) {
      this.previewType = 'video';
      this.previewSrc = media.signedUrl;
    } else if (media.mime.includes('pdf')) {
      this.previewType = 'pdf';
    }

    this.mediaName = this.formatMediaName(media);
  }

  formatMediaName(media: { mime: string; name: string }): string {
    const extension = media.mime.split('/')[1]; // Get the media extension from the MIME type
    return `${media.name}.${extension}`;
  }

  removeMedia(): void {
    this.previewSrc = null;
    this.selectedMedia = undefined;
    this.mediaName ='';
  }

  submitManualPlaceholders(): void {
    if (this.placeholdersForm.valid) {
      this.error = false;

      const placeholders: { placeholderId: string; replacement: string }[] = [];
      Object.keys(this.placeholdersForm.controls).forEach((key) => {
        placeholders.push({
          placeholderId: key,
          replacement: this.placeholdersForm.get(key)?.value || '',
        });
      });

      if (this.template) {
        if (this.template.attachMedia && !this.selectedMedia) {
          this.error = true;
        } else {
          this.emitSelectTemplate(
            this.template,
            placeholders,
            this.selectedMedia,
            this.previewType,
            this.buttonLinkForm.get('buttonLink')?.value
          );
        }
      }
    } else {
      this.error = true;
    }
  }

  private getMergeFieldReplacement(
    mergeField: TemplateMergeField,
    placeholders: { placeholderId: string; replacement: string }[]
  ): string {
    switch (mergeField.content) {
      case MergeFieldContent.PracticeName:
        return (
          this.conversationService.practice?.name ||
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );

      case MergeFieldContent.ClientName:
        return (
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement || mergeField.placeholder
        );

      case MergeFieldContent.ClientPostcode:
        return (
          this.client?.postcode ||
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );

      case MergeFieldContent.Custom:
        return (
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );
    }

    return mergeField.placeholder;
  }

  getTemplateText(
    template: Template,
    placeholders: { placeholderId: string; replacement: string }[]
  ): string {
    let templateText = template.body;

    template.mergeFields.forEach((mergeField) => {
      templateText = templateText.replace(
        mergeField.placeholder,
        this.getMergeFieldReplacement(mergeField, placeholders)
      );
    });

    return templateText;
  }

  ngAfterViewInit(): void {
    if(this.manualPlaceholders){
      for(let manualplaceholder of this.manualPlaceholders){
        this.placeholdersForm.controls[manualplaceholder.id].valueChanges.subscribe((value)=>{
          const placeholders: { placeholderId: string; replacement: string }[] = [];
          Object.keys(this.placeholdersForm.controls).forEach((key) => {
            placeholders.push({
              placeholderId: key,
              replacement: this.placeholdersForm.get(key)?.value || '',
            });
          });
          if(this.template){
            this.conversationService.updatePreviewConversation(this.template,placeholders)
          }
        })
      }
    }
  }

  emitSelectTemplate(
    template: Template,
    placeholders: { placeholderId: string; replacement: string }[],
    media?: Media,
    previewType?: string,
    buttonLink?: string
  ): void {
    this.result = {
      ...this.result,
      template,
      placeholders,
      media,
      previewType,
      buttonLink
    };
    this.conversationService.updatePreviewConversation(template, placeholders)
    this.ref.close(this.result);
  }
}
