<div class="desktop-header" [style.background-color]="accentColor">
  <div class="page-title" [style.color]="headerTextColor">
    <page-title></page-title>
  </div>

  <div class="center-block">
    <div class="create" (clickOutside)="showingCreateActions = false" joyrideStep="step3" stepPosition="bottom" [stepContent]="step3Content">
      <p-button label="Create" (onClick)="toggleCreateActions()" styleClass="p-button-secondary" iconPos="right" [icon]="showingCreateActions ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></p-button>
      <ng-container *ngIf="showingCreateActions">
        <div class="create-actions">
          <create-actions
            (actionSelected)="handleActionSelected()"
          ></create-actions>
        </div>
      </ng-container>
    </div>

    <div class="search-wrap" joyrideStep="step16" [stepContent]="step16Content" stepPosition="bottom">
      <search-bar></search-bar>
    </div>
  </div>

  <div class="right-block">
    <ng-container *ngIf="practice">
      <div class="pms-status">
        <ng-container *ngIf="pmsHealthy$ && (pmsHealthy$ | ngrxPush) === false">
          <i class="pi pi-exclamation-triangle" pTooltip="{{this.practice.pms}}  PMS Connection Error" tooltipStyleClass="warning-tooltip" tooltipPosition="left"></i>
        </ng-container>
        <ng-container *ngIf="pmsHealthy$ && (pmsHealthy$ | ngrxPush) === true">
          <i class="pi pi-check-circle" pTooltip="{{this.practice.pms}} PMS Connected" tooltipStyleClass="success-tooltip" tooltipPosition="left"></i>
        </ng-container>
      </div>
      <div class="link-icon" (clickOutside)="closePracticeContactDetails()">
        <p-button [label]="practice.name" (onClick)="togglePracticeContactDetails()" styleClass="p-button-primary p-button-truncate-text" iconPos="right" [icon]="showPracticeContactDetails ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></p-button>
        <div class="link-icon-sub-nav" [class.active]="showPracticeContactDetails">
          <div class="practice-name">{{ practice.name }}</div>
          <ng-container *ngIf="practice.whatsapp_sender">
            <div class="practice-contact">
              <img src="assets/images/icons/WhatsApp.svg">
              <div>{{ practice.whatsapp_sender }}</div>
            </div>
          </ng-container>
          <ng-container *ngIf="practice.facebookPageId">
            <div class="practice-contact">
              <img src="assets/images/icons/facebook-messenger.svg">
              <a href="https://facebook.com/{{ practice.facebookPageId }}" target="_blank">Go to Facebook Page</a>
            </div>
          </ng-container>
          <div class="practice-selector" (click)="$event.stopPropagation()">
            <practice-selector (practiceChanged)="closePracticeContactDetails()"></practice-selector>
          </div>
        </div>
      </div>
    </ng-container>

    <a (click)="handleGoToHelpCenter()" [href]="helpLink" target="_blank" class="link-icon" joyrideStep="step15" [stepContent]="step15Content" stepPosition="left">
      <div class="icon-holder" [style.color]="headerTextColor">
        <svg class="icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="8" stroke="currentColor" stroke-width="1.7"/>
          <path d="M9.17486 10.5276C9.39612 10.5276 9.57549 10.3482 9.57549 10.127C9.55149 9.55095 9.73148 9.19558 10.2115 8.64358C10.7275 8.05558 11.2435 7.46758 11.2435 6.59158C11.2435 5.63158 10.5595 4.76758 9.07149 4.76758C8.47059 4.76758 7.83062 5.06877 7.37366 5.33204C7.16944 5.4497 7.07071 5.68794 7.11776 5.91889C7.20159 6.33036 7.67469 6.49624 8.02501 6.2647C8.28294 6.09423 8.54951 5.95558 8.74749 5.95558C9.41949 5.96758 9.74349 6.30358 9.74349 6.80758C9.74349 7.27558 9.43149 7.69558 8.97549 8.24758C8.41149 8.91958 8.14061 9.56361 8.20061 10.1996C8.23953 10.3905 8.40741 10.5276 8.6022 10.5276H9.17486ZM8.87949 13.1316C9.44349 13.1316 9.80349 12.7236 9.80349 12.1956C9.79149 11.6436 9.43149 11.2596 8.89149 11.2596C8.36349 11.2596 7.97949 11.6436 7.97949 12.1956C7.97949 12.7236 8.35149 13.1316 8.87949 13.1316Z" fill="currentColor"/>
        </svg>
      </div>
    </a>

    <div class="link-icon" (click)="toggleNotifications()">
      <div class="icon-holder" [style.color]="headerTextColor">
        <svg class="icon" width="30" height="35" viewBox="0 0 30 35" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" fill="currentColor" d="M29.5059 28.6306C29.5059 28.2675 29.3616 27.9192 29.1048 27.6624L28.2918 26.8494C26.9963 25.5538 26.2684 23.7966 26.2684 21.9644V15.1134C26.2684 10.0953 22.9823 5.67612 18.1747 4.25162V3.78218C18.1747 2.92354 17.8336 2.10007 17.2264 1.49292C16.6193 0.88577 15.7958 0.544678 14.9372 0.544678C14.0785 0.544678 13.255 0.88577 12.6479 1.49292C12.0408 2.10007 11.6997 2.92354 11.6997 3.78218V4.25162C6.89197 5.67612 3.60591 10.0953 3.60591 15.1134V21.9644C3.60591 23.7966 2.87806 25.5538 1.58247 26.8494L0.769485 27.6624C0.51268 27.9192 0.368408 28.2675 0.368408 28.6306C0.368408 29.3869 0.981492 30 1.73777 30H28.1365C28.8928 30 29.5059 29.3869 29.5059 28.6306ZM17.2264 33.5902C17.8336 32.983 18.1747 32.1596 18.1747 31.3009H11.6997C11.6997 32.1596 12.0408 32.983 12.6479 33.5902C13.255 34.1973 14.0785 34.5384 14.9372 34.5384C15.7958 34.5384 16.6193 34.1973 17.2264 33.5902ZM23.2085 26.9824H6.66597C6.06867 26.9824 5.58447 26.4982 5.58447 25.9009C5.58447 25.7815 5.60426 25.6628 5.64302 25.5499L6.02854 24.4264C6.3079 23.6122 6.45047 22.7575 6.45047 21.8967V15.6254C6.45047 11.7136 8.91164 8.26867 12.5125 7.15821C13.8148 6.75662 15.7166 6.60674 17.362 7.15821C20.9629 8.26867 23.424 11.7136 23.424 15.6254V21.8967C23.424 22.7575 23.5666 23.6122 23.846 24.4264L24.2315 25.5499C24.2702 25.6628 24.29 25.7815 24.29 25.9009C24.29 26.4982 23.8058 26.9824 23.2085 26.9824Z"/>
        </svg>
        <ng-container *ngIf="((notificationCount$ | async) || 0) > 0 && ((notificationCount$ | async) || 0) < 10">
          <div class="badge">{{ notificationCount$ | async }}</div>
        </ng-container>
        <ng-container *ngIf="((notificationCount$ | async) || 0) > 9">
          <div class="badge">9+</div>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="user">
      <div class="link-icon" (clickOutside)="closeUserMenu()">
        <div class="icon-holder" (click)="toggleUserMenu()">
          <user-avatar [user]="user" size="small"></user-avatar>
        </div>
        <div class="link-icon-sub-nav small"  [class.active]="userMenuOpen">
          <a [href]="profileLink" target="_blank" class="sub-nav-link">
            <img class="icon" src="/assets/images/icons/nav/user.svg" alt="">
            My profile
          </a>
          <a [href]="settingsLink" target="_blank" class="sub-nav-link">
            <img class="icon" src="/assets/images/icons/nav/bell.svg" alt="">
            Notifications
          </a>
          <div (click)="logout()" class="sub-nav-link">
            <img class="icon" src="/assets/images/icons/nav/logout.svg" alt="">
            Log out
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<p-dialog
  header="Notifications"
  [(visible)]="notificationsOpen"
  [modal]="true"
  [style]="{width: '450px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <p-header>
    <div class="dialog-header-notification">
      Notifications
      <a [href]="settingsLink" target="_blank" class="nav-link" tooltipPosition="right" pTooltip="Manage notifications" >
        <img class="cog-icon" src="/assets/images/icons/nav/cog.svg" alt="">
      </a>
    </div>
  </p-header>
  <notifications-list (linkClicked)="toggleNotifications()"></notifications-list>
</p-dialog>

<p-dialog
  header="New Payment Request"
  [(visible)]="paymentRequestOpen"
  [modal]="true"
  [style]="{width: '600px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleNewPaymentDialogClosed()"
>
  <ng-container *ngIf="paymentRequestOpen">
    <new-payment [practice]="practice"></new-payment>
  </ng-container>
</p-dialog>

<p-dialog
  [(visible)]="newCampaignOpen"
  [modal]="true"
  [style]="{width: '800px'}"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  styleClass="create-campaign-dialog"
  (onHide)="closeCampaignDialog()"
>
  <ng-template pTemplate="header">
    <div class="dialog-header">
      Create new broadcast
    </div>
  </ng-template>
  <ng-container *ngIf="newCampaignOpen">
    <campaign-add-form (campaignCreated)="closeCampaignDialog()" buttonClass="p-button-success"></campaign-add-form>
  </ng-container>
  <div class="bottom-row">
    <div class="left"></div>
    <div class="center">
      <a [href]="helpLink + '/category/faq/post/how-do-i-send-a-broadcast-message'" target="_blank"><img
          src="/assets/images/icons/question_mark.png" height="20" class="help-icon" alt=""> Need help?</a>
    </div>
    <div class="right">
    </div>
  </div>
</p-dialog>

<ng-template #step3Content>
  <div class="tour-item">
    <div class="top mint"><img src="/assets/images/icons/tour/ticket.svg" height="78" alt="" class="icon"></div>
    <div class="content">
      <div class="title">Start a conversation</div>
      <div class="description">Start a new client interaction directly from your top toolbar</div>
    </div>
  </div>
</ng-template>

<ng-template #step15Content>
  <div class="tour-item">
    <div class="top blue"><img src="/assets/images/icons/tour/help.svg" height="113" alt="" class="icon"></div>
    <div class="content">
      <div class="title">We are here to help</div>
      <div class="description">Access more training materials or drop us a line with a question or feedback</div>
    </div>
  </div>
</ng-template>

<ng-template #step16Content>
  <div class="tour-item">
    <div class="top blue"><img src="/assets/images/icons/tour/search.svg" height="113" alt="" class="icon"></div>
    <div class="content">
      <div class="title">Search for interactions</div>
      <div class="description">Quickly find what you're looking for by entering a keyword here</div>
    </div>
  </div>
</ng-template>
