import { createAction, props } from '@ngrx/store';
import { Practice } from 'src/app/models/Practice';
import { User } from 'src/app/models/User';
import { Group } from '../../models/Group';
import {Template} from "../../interfaces/template";
import { PracticeConfig } from '../../enums/practice-config';
import { PracticeConfigInterface } from '../../interfaces/practice-config.interface';

export const GetPracticeStaff = createAction(
    '[Practices] Get Practice Staff'
);

export const GetPracticeStaffSuccess = createAction(
    '[Practices] Get Practice Staff - Success',
    props<{users: User[]}>()
);

export const GetPracticeGroups = createAction(
  '[Practices] Get Practice Groups'
);

export const GetPracticeGroupsSuccess = createAction(
  '[Practices] Get Practice Groups - Success',
  props<{groups: Group[]}>()
);

export const GetUserPractices = createAction(
    '[Practices] Get User Practices'
);

export const GetUserPracticesSuccess = createAction(
    '[Practices] Get User Practices - Success',
    props<{practices: Practice[]}>()
);

export const SetSelectedPractice = createAction(
    '[Practices] Set Selected Practice',
    props<{practice: Practice}>()
);

export const ClearAvailablePractices = createAction(
    '[Practices] Clear Available Practices'
);

export const GetPracticeTemplates = createAction(
  '[Practices] Get Templates'
);

export const GetPracticeTemplatesSuccess = createAction(
  '[Practices] Get Templates - Success',
  props<{templates: Template[]}>()
);

export const GetPracticeConfig = createAction(
  '[Practices] Get Config'
);

export const GetPracticeConfigSuccess = createAction(
  '[Practices] Get Config - Success',
  props<{config: PracticeConfigInterface}>()
);
