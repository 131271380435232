import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { Client } from "../../../models/Client";
import { Contact } from "../../../models/Contact";
import { Channel } from "../../../enums/channel";
import { Practice } from "../../../models/Practice";
import { UntypedFormBuilder } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../state/reducers";
import {
  getCurrentPractice,
  getCurrentPracticeSites,
} from "../../../practices/state/selectors";
import { takeWhile } from "rxjs/operators";
import {
  CreateFormSubmission,
  GetFormToSend,
  GetMoreSendFormPatients,
  SearchForms,
  SetFormRequestClient,
  SetFormRequestContact,
} from "../../state/actions";
import {
  getCreateFormSubmissionInProgress,
  getCurrentFormToSend,
  getFormRequestChannel,
  getFormRequestClient,
  getFormRequestContact,
  getFormRequestPatients,
  IsFormRequestPatientsLoading,
} from "../../state/selectors";
import { Form } from "../../interfaces/form";
import { PageField } from "../../interfaces/page-field";
import { fieldsContainPatientInformation } from "../../../helpers/fields-contain-patient-information";
import { Patient } from "../../../models/Patient";
import { getUser } from "../../../auth/state/selectors";
import { User } from "../../../models/User";
import { Site } from "../../../models/Site";

interface ChannelOption {
  name: string;
  code: Channel;
  className: string;
  icon: string;
  inactive: boolean;
}

@Component({
  selector: "new-form-request",
  templateUrl: "./new-form-request.component.html",
  styleUrls: ["./new-form-request.component.scss"],
})
export class NewFormRequestComponent implements OnInit, OnDestroy {
  @ViewChildren("prefillInputs") prefillInputs:
    | QueryList<ElementRef>
    | undefined;
  alive = true;
  step = 0;
  createInProgress = false;
  selectedClient: Client | null = null;
  selectedContact: Contact | null = null;
  patients: Patient[] = [];
  selectedPatient: Patient | null = null;
  presetChannel: Channel | null = null;
  practice: Practice | null = null;
  channels: ChannelOption[] = [
    {
      name: Channel.WHATSAPP,
      code: Channel.WHATSAPP,
      className: Channel.WHATSAPP.toLowerCase(),
      icon: "WhatsApp.svg",
      inactive: false,
    },
    {
      name: Channel.SMS,
      code: Channel.SMS,
      className: Channel.SMS.toLowerCase(),
      icon: "SMS.svg",
      inactive: false,
    },
  ];
  selectedChannel?: ChannelOption;
  currentChannelOption: ChannelOption = this.channels[0];
  selectedForm: Form | null = null;
  fullForm: Form | null = null;
  fieldsToPrefill: PageField[] = [];
  formLoading = false;
  patientsLoading = false;
  patientsPage = 0;
  user?: User;
  sites: Site[] = [];
  selectedSiteId = 0;
  siteError: string | null = null;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.subscribeToCurrentUser();
    this.subscribeToCurrentPractice();
    this.subscribeToPracticeSites();
    this.subscribeToClient();
    this.subscribeToContact();
    this.subscribeToChannel();
    this.subscribeToCurrentForm();
    this.subscribeToPatientsLoading();
    this.subscribeToPatients();
    this.subscribeToCreateFormSubmissionInProgress();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  subscribeToCurrentPractice(): void {
    this.store
      .pipe(select(getCurrentPractice))
      .pipe(takeWhile(() => this.alive))
      .subscribe((practice) => {
        this.practice = practice;

        if (practice) {
          this.store.dispatch(SearchForms({ search: "" }));

          let channelCode = Channel.WHATSAPP;
          if (
            practice.whatsapp_channel === Channel.WHATSAPP360 ||
            practice.whatsapp_channel === Channel.WHATSAPP360CLOUD
          ) {
            channelCode = practice.whatsapp_channel;
          }

          this.channels = [
            {
              name: Channel.WHATSAPP,
              code: channelCode,
              className: Channel.WHATSAPP.toLowerCase(),
              icon: "WhatsApp.svg",
              inactive: false,
            },
            ...this.channels.filter(
              (channelOpt) =>
                channelOpt.code !== Channel.WHATSAPP &&
                channelOpt.code !== Channel.WHATSAPP360 &&
                channelOpt.code !== Channel.WHATSAPP360CLOUD,
            ),
          ];
          this.currentChannelOption = this.channels[0];
        }
      });
  }

  subscribeToCurrentUser(): void {
    this.store
      .pipe(select(getUser))
      .pipe(takeWhile(() => this.alive))
      .subscribe((user) => {
        if (user) {
          this.user = user;
        }
      });
  }

  subscribeToPracticeSites(): void {
    this.store
      .select(getCurrentPracticeSites)
      .pipe(takeWhile(() => this.alive))
      .subscribe((sites) => {
        this.sites = sites;
      });
  }

  subscribeToPatientsLoading(): void {
    this.store
      .pipe(select(IsFormRequestPatientsLoading))
      .pipe(takeWhile(() => this.alive))
      .subscribe((loading) => {
        this.patientsLoading = loading;
      });
  }

  subscribeToCreateFormSubmissionInProgress(): void {
    this.store
      .pipe(select(getCreateFormSubmissionInProgress))
      .pipe(takeWhile(() => this.alive))
      .subscribe((inProgress) => {
        this.createInProgress = inProgress;
      });
  }

  subscribeToPatients(): void {
    this.store
      .pipe(select(getFormRequestPatients))
      .pipe(takeWhile(() => this.alive))
      .subscribe((patients) => {
        this.patients = patients;
      });
  }

  subscribeToClient(): void {
    this.store
      .pipe(select(getFormRequestClient))
      .pipe(takeWhile(() => this.alive))
      .subscribe((client) => {
        this.selectedClient = client;
        this.goToNextStep();
      });
  }

  subscribeToContact(): void {
    this.store
      .pipe(select(getFormRequestContact))
      .pipe(takeWhile(() => this.alive))
      .subscribe((contact) => {
        this.selectedContact = contact;
        this.goToNextStep();
      });
  }

  subscribeToChannel(): void {
    this.store
      .pipe(select(getFormRequestChannel))
      .pipe(takeWhile(() => this.alive))
      .subscribe((channel) => {
        this.presetChannel = channel;
        this.goToNextStep();
      });
  }

  subscribeToCurrentForm(): void {
    this.store
      .pipe(select(getCurrentFormToSend))
      .pipe(takeWhile(() => this.alive))
      .subscribe((form) => {
        this.fieldsToPrefill = [];
        this.fullForm = form;

        if (form) {
          form.formPages?.forEach((page) => {
            page.pageFields
              ?.filter((field) => field.prefill)
              .forEach((prefillField) => {
                this.fieldsToPrefill.push(prefillField);
              });
          });

          this.formLoading = false;

          this.goToNextStep();
        }
      });
  }

  selectClient(client: Client): void {
    this.store.dispatch(SetFormRequestClient({ client }));
  }

  selectContact(contact: Contact): void {
    this.store.dispatch(SetFormRequestContact({ contact }));
  }

  goToNextStep(): void {
    let step = 0;

    if (this.selectedClient) {
      step = 1;
    }

    if (this.selectedClient && this.selectedContact) {
      step = 2;
    }

    if (
      this.selectedClient &&
      this.selectedContact &&
      (this.presetChannel || this.selectedChannel)
    ) {
      step = 3;
    }

    if (
      this.selectedClient &&
      this.selectedContact &&
      (this.presetChannel || this.selectedChannel) &&
      this.selectedForm
    ) {
      step = 4;
    }

    if (
      this.selectedClient &&
      this.selectedContact &&
      (this.presetChannel || this.selectedChannel) &&
      this.selectedForm &&
      (this.selectedPatient ||
        (!this.formLoading &&
          !fieldsContainPatientInformation(this.fieldsToPrefill)))
    ) {
      if (this.fieldsToPrefill.length === 0) {
        if (this.selectedForm.takePayment && this.sites.length > 1) {
          step = 6;
        } else if (this.selectedPatient) {
          this.sendForm();
        }
      } else {
        step = 5;
      }
    }

    this.step = step;
  }

  goBackTo(step: number): void {
    if (step === 0) {
      this.store.dispatch(SetFormRequestClient({}));
    }

    if (step === 1) {
      this.store.dispatch(SetFormRequestContact({}));
    }

    if (step === 2) {
      if (this.presetChannel) {
        // go back past channel step if channel is preset
        this.goBackTo(1);
      } else {
        this.selectedChannel = undefined;
        this.step = 2;
      }
    }

    if (step === 3) {
      this.selectedForm = null;
      this.fullForm = null;
      this.step = 3;
    }

    if (step === 4) {
      this.selectedPatient = null;
      if (!fieldsContainPatientInformation(this.fieldsToPrefill)) {
        this.goBackTo(3);
      } else {
        this.step = 4;
      }
    }

    if (step === 5) {
      if (this.fieldsToPrefill.length === 0) {
        this.goBackTo(4);
      } else {
        this.step = 5;
      }
    }
  }

  goToFormStep(): void {
    this.selectedChannel = this.currentChannelOption;
    this.step = 3;
  }

  handleFormSelected(form: Form): void {
    this.selectedForm = form;
    this.getFullForm();
    this.goToNextStep();
  }

  getFullForm(): void {
    if (this.selectedForm) {
      this.formLoading = true;
      this.store.dispatch(GetFormToSend({ formId: this.selectedForm.id }));
    }
  }

  sendForm(): void {
    this.siteError = null;

    if (
      this.selectedForm &&
      this.practice &&
      this.selectedClient &&
      this.selectedContact
    ) {
      if (
        this.selectedSiteId === 0 &&
        this.sites.length > 1 &&
        this.selectedForm.takePayment
      ) {
        this.siteError = "Please select a site before sending.";
        return;
      }

      this.store.dispatch(
        CreateFormSubmission({
          dto: {
            formId: this.selectedForm.id.toString(),
            practiceId: this.practice.coreId,
            ownerId: this.user?.id || "0",
            vbResourceId:
              this.sites.find(
                (site) => site.id.toString() === this.selectedSiteId.toString(),
              )?.vetbookerResourceId || null,
            practiceCountry: this.practice.country,
            clientPmsId: this.selectedClient.pmsId,
            contactNumber: this.selectedContact.value,
            data: this.prefillInputs
              ? this.prefillInputs.map((input) => {
                  const matchingPrefill = this.fieldsToPrefill.find(
                    (field) =>
                      field.id.toString() ===
                      input.nativeElement.name.toString(),
                  );
                  return {
                    fieldId: input.nativeElement.name,
                    fieldLabel: matchingPrefill?.label || "",
                    fieldValue: input.nativeElement.value,
                  };
                })
              : [],
          },
          client: this.selectedClient,
          contact: this.selectedContact,
          channel:
            this.presetChannel ||
            this.selectedChannel?.code ||
            Channel.WHATSAPP360CLOUD,
        }),
      );
    }
  }

  selectPatient(patient: Patient): void {
    this.selectedPatient = patient;
    this.goToNextStep();
  }

  handleMorePatients(): void {
    this.patientsPage++;
    this.store.dispatch(GetMoreSendFormPatients({ page: this.patientsPage }));
  }

  goToSiteStep(): void {
    this.step = 6;
  }
}
